*, ::before, ::after {
  border-color: currentColor;
}

.space-x-10 {
  margin-left: 5px;
  margin-right: 5px;
}

.space-x-15 {
  margin-left: 7.5px;
  margin-right: 7.5px;
}

.space-x-20 {
  margin-left: 10px;
  margin-right: 10px;
}

.space-x-30 {
  margin-left: 15px;
  margin-right: 15px;
}

.space-x-40 {
  margin-left: 20px;
  margin-right: 20px;
}

.space-x-50 {
  margin-left: 25px;
  margin-right: 25px;
}

.space-x-60 {
  margin-left: 30px;
  margin-right: 30px;
}

.space-x-64 {
  margin-left: 32px;
  margin-right: 32px;
}

.space-x-92 {
  margin-left: 46px;
  margin-right: 46px;
}

.space-y-10 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.space-y-15 {
  margin-top: 7.5px;
  margin-bottom: 7.5px;
}

.space-y-20 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.space-y-30 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.space-y-40 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.space-y-50 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.space-y-60 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.space-y-64 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.space-y-92 {
  margin-top: 46px;
  margin-bottom: 46px;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
}

.isolate {
  isolation: isolate;
}

.isolation-auto {
  isolation: auto;
}

.m-10 {
  margin: 5px;
}

.m-15 {
  margin: 7.5px;
}

.m-20 {
  margin: 10px;
}

.m-30 {
  margin: 15px;
}

.m-40 {
  margin: 20px;
}

.m-50 {
  margin: 25px;
}

.m-60 {
  margin: 30px;
}

.m-64 {
  margin: 32px;
}

.m-92 {
  margin: 46px;
}

.m-auto {
  margin: auto;
}

.-m-10 {
  margin: -5px;
}

.-m-15 {
  margin: -7.5px;
}

.-m-20 {
  margin: -10px;
}

.-m-30 {
  margin: -15px;
}

.-m-40 {
  margin: -20px;
}

.-m-50 {
  margin: -25px;
}

.-m-60 {
  margin: -30px;
}

.-m-64 {
  margin: -32px;
}

.-m-92 {
  margin: -46px;
}

.mx-10 {
  margin-left: 5px;
  margin-right: 5px;
}

.mx-15 {
  margin-left: 7.5px;
  margin-right: 7.5px;
}

.mx-20 {
  margin-left: 10px;
  margin-right: 10px;
}

.mx-30 {
  margin-left: 15px;
  margin-right: 15px;
}

.mx-40 {
  margin-left: 20px;
  margin-right: 20px;
}

.mx-50 {
  margin-left: 25px;
  margin-right: 25px;
}

.mx-60 {
  margin-left: 30px;
  margin-right: 30px;
}

.mx-64 {
  margin-left: 32px;
  margin-right: 32px;
}

.mx-92 {
  margin-left: 46px;
  margin-right: 46px;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.-mx-10 {
  margin-left: -5px;
  margin-right: -5px;
}

.-mx-15 {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.-mx-20 {
  margin-left: -10px;
  margin-right: -10px;
}

.-mx-30 {
  margin-left: -15px;
  margin-right: -15px;
}

.-mx-40 {
  margin-left: -20px;
  margin-right: -20px;
}

.-mx-50 {
  margin-left: -25px;
  margin-right: -25px;
}

.-mx-60 {
  margin-left: -30px;
  margin-right: -30px;
}

.-mx-64 {
  margin-left: -32px;
  margin-right: -32px;
}

.-mx-92 {
  margin-left: -46px;
  margin-right: -46px;
}

.my-10 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.my-15 {
  margin-top: 7.5px;
  margin-bottom: 7.5px;
}

.my-20 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my-30 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.my-40 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my-50 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.my-60 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.my-64 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.my-92 {
  margin-top: 46px;
  margin-bottom: 46px;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.-my-10 {
  margin-top: -5px;
  margin-bottom: -5px;
}

.-my-15 {
  margin-top: -7.5px;
  margin-bottom: -7.5px;
}

.-my-20 {
  margin-top: -10px;
  margin-bottom: -10px;
}

.-my-30 {
  margin-top: -15px;
  margin-bottom: -15px;
}

.-my-40 {
  margin-top: -20px;
  margin-bottom: -20px;
}

.-my-50 {
  margin-top: -25px;
  margin-bottom: -25px;
}

.-my-60 {
  margin-top: -30px;
  margin-bottom: -30px;
}

.-my-64 {
  margin-top: -32px;
  margin-bottom: -32px;
}

.-my-92 {
  margin-top: -46px;
  margin-bottom: -46px;
}

.mt-10 {
  margin-top: 5px;
}

.mt-15 {
  margin-top: 7.5px;
}

.mt-20 {
  margin-top: 10px;
}

.mt-30 {
  margin-top: 15px;
}

.mt-40 {
  margin-top: 20px;
}

.mt-50 {
  margin-top: 25px;
}

.mt-60 {
  margin-top: 30px;
}

.mt-64 {
  margin-top: 32px;
}

.mt-92 {
  margin-top: 46px;
}

.mt-auto {
  margin-top: auto;
}

.-mt-10 {
  margin-top: -5px;
}

.-mt-15 {
  margin-top: -7.5px;
}

.-mt-20 {
  margin-top: -10px;
}

.-mt-30 {
  margin-top: -15px;
}

.-mt-40 {
  margin-top: -20px;
}

.-mt-50 {
  margin-top: -25px;
}

.-mt-60 {
  margin-top: -30px;
}

.-mt-64 {
  margin-top: -32px;
}

.-mt-92 {
  margin-top: -46px;
}

.mr-10 {
  margin-right: 5px;
}

.mr-15 {
  margin-right: 7.5px;
}

.mr-20 {
  margin-right: 10px;
}

.mr-30 {
  margin-right: 15px;
}

.mr-40 {
  margin-right: 20px;
}

.mr-50 {
  margin-right: 25px;
}

.mr-60 {
  margin-right: 30px;
}

.mr-64 {
  margin-right: 32px;
}

.mr-92 {
  margin-right: 46px;
}

.mr-auto {
  margin-right: auto;
}

.-mr-10 {
  margin-right: -5px;
}

.-mr-15 {
  margin-right: -7.5px;
}

.-mr-20 {
  margin-right: -10px;
}

.-mr-30 {
  margin-right: -15px;
}

.-mr-40 {
  margin-right: -20px;
}

.-mr-50 {
  margin-right: -25px;
}

.-mr-60 {
  margin-right: -30px;
}

.-mr-64 {
  margin-right: -32px;
}

.-mr-92 {
  margin-right: -46px;
}

.mb-10 {
  margin-bottom: 5px;
}

.mb-15 {
  margin-bottom: 7.5px;
}

.mb-20 {
  margin-bottom: 10px;
}

.mb-30 {
  margin-bottom: 15px;
}

.mb-40 {
  margin-bottom: 20px;
}

.mb-50 {
  margin-bottom: 25px;
}

.mb-60 {
  margin-bottom: 30px;
}

.mb-64 {
  margin-bottom: 32px;
}

.mb-92 {
  margin-bottom: 46px;
}

.mb-auto {
  margin-bottom: auto;
}

.-mb-10 {
  margin-bottom: -5px;
}

.-mb-15 {
  margin-bottom: -7.5px;
}

.-mb-20 {
  margin-bottom: -10px;
}

.-mb-30 {
  margin-bottom: -15px;
}

.-mb-40 {
  margin-bottom: -20px;
}

.-mb-50 {
  margin-bottom: -25px;
}

.-mb-60 {
  margin-bottom: -30px;
}

.-mb-64 {
  margin-bottom: -32px;
}

.-mb-92 {
  margin-bottom: -46px;
}

.ml-10 {
  margin-left: 5px;
}

.ml-15 {
  margin-left: 7.5px;
}

.ml-20 {
  margin-left: 10px;
}

.ml-30 {
  margin-left: 15px;
}

.ml-40 {
  margin-left: 20px;
}

.ml-50 {
  margin-left: 25px;
}

.ml-60 {
  margin-left: 30px;
}

.ml-64 {
  margin-left: 32px;
}

.ml-92 {
  margin-left: 46px;
}

.ml-auto {
  margin-left: auto;
}

.-ml-10 {
  margin-left: -5px;
}

.-ml-15 {
  margin-left: -7.5px;
}

.-ml-20 {
  margin-left: -10px;
}

.-ml-30 {
  margin-left: -15px;
}

.-ml-40 {
  margin-left: -20px;
}

.-ml-50 {
  margin-left: -25px;
}

.-ml-60 {
  margin-left: -30px;
}

.-ml-64 {
  margin-left: -32px;
}

.-ml-92 {
  margin-left: -46px;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.inline-table {
  display: inline-table;
}

.table-caption {
  display: table-caption;
}

.table-cell {
  display: table-cell;
}

.table-column {
  display: table-column;
}

.table-column-group {
  display: table-column-group;
}

.table-footer-group {
  display: table-footer-group;
}

.table-header-group {
  display: table-header-group;
}

.table-row-group {
  display: table-row-group;
}

.table-row {
  display: table-row;
}

.flow-root {
  display: flow-root;
}

.grid {
  display: grid;
}

.inline-grid {
  display: inline-grid;
}

.contents {
  display: contents;
}

.list-item {
  display: list-item;
}

.hidden {
  display: none;
}

.h-54 {
  height: 27px;
}

.h-64 {
  height: 32px;
}

.h-88 {
  height: 44px;
}

.h-92 {
  height: 46px;
}

.h-98 {
  height: 49px;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.w-30 {
  width: 15px;
}

.w-88 {
  width: 44px;
}

.w-98 {
  width: 49px;
}

.w-300 {
  width: 150px;
}

.w-540 {
  width: 270px;
}

.w-auto {
  width: auto;
}

.w-1_6 {
  width: 16.6666667%;
}

.w-1_3 {
  width: 33.3333333%;
}

.w-1_4 {
  width: 25%;
}

.w-1_2 {
  width: 50%;
}

.w-2_3 {
  width: 66.6666666%;
}

.w-5_6 {
  width: 83.3333333%;
}

.w-full {
  width: 100%;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-initial {
  flex: 0 1 auto;
}

.flex-none {
  flex: none;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow {
  flex-grow: 1;
}

.cursor-auto {
  cursor: auto;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-wait {
  cursor: wait;
}

.cursor-text {
  cursor: text;
}

.cursor-move {
  cursor: move;
}

.cursor-help {
  cursor: help;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.content-center {
  align-content: center;
}

.content-start {
  align-content: flex-start;
}

.content-end {
  align-content: flex-end;
}

.content-between {
  align-content: space-between;
}

.content-around {
  align-content: space-around;
}

.content-evenly {
  align-content: space-evenly;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.gap-20 {
  gap: 10px;
}

.gap-30 {
  gap: 15px;
}

.gap-x-20 {
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.gap-x-30 {
  -webkit-column-gap: 15px;
          column-gap: 15px;
}

.gap-y-20 {
  row-gap: 10px;
}

.gap-y-30 {
  row-gap: 15px;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-visible {
  overflow-x: visible;
}

.overflow-y-visible {
  overflow-y: visible;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
}

.overflow-clip {
  text-overflow: clip;
}

.rounded-10 {
  border-radius: 5px;
}

.rounded-20 {
  border-radius: 10px;
}

.rounded-half {
  border-radius: 50%;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-t-10 {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.rounded-t-20 {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.rounded-t-half {
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.rounded-t-full {
  border-top-left-radius: 9999px;
  border-top-right-radius: 9999px;
}

.rounded-r-10 {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.rounded-r-20 {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.rounded-r-half {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.rounded-b-10 {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.rounded-b-20 {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.rounded-b-half {
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}

.rounded-b-full {
  border-bottom-right-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.rounded-l-10 {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.rounded-l-20 {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.rounded-l-half {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.rounded-l-full {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.rounded-tl-10 {
  border-top-left-radius: 5px;
}

.rounded-tl-20 {
  border-top-left-radius: 10px;
}

.rounded-tl-half {
  border-top-left-radius: 50%;
}

.rounded-tl-full {
  border-top-left-radius: 9999px;
}

.rounded-tr-10 {
  border-top-right-radius: 5px;
}

.rounded-tr-20 {
  border-top-right-radius: 10px;
}

.rounded-tr-half {
  border-top-right-radius: 50%;
}

.rounded-tr-full {
  border-top-right-radius: 9999px;
}

.rounded-br-10 {
  border-bottom-right-radius: 5px;
}

.rounded-br-20 {
  border-bottom-right-radius: 10px;
}

.rounded-br-half {
  border-bottom-right-radius: 50%;
}

.rounded-br-full {
  border-bottom-right-radius: 9999px;
}

.rounded-bl-10 {
  border-bottom-left-radius: 5px;
}

.rounded-bl-20 {
  border-bottom-left-radius: 10px;
}

.rounded-bl-half {
  border-bottom-left-radius: 50%;
}

.rounded-bl-full {
  border-bottom-left-radius: 9999px;
}

.border-0 {
  border-width: 0;
}

.border-1 {
  border-width: 1px;
}

.border {
  border-width: 1px;
}

.border-t-0 {
  border-top-width: 0;
}

.border-t-1 {
  border-top-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-r-0 {
  border-right-width: 0;
}

.border-r-1 {
  border-right-width: 1px;
}

.border-r {
  border-right-width: 1px;
}

.border-b-0 {
  border-bottom-width: 0;
}

.border-b-1 {
  border-bottom-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l-0 {
  border-left-width: 0;
}

.border-l-1 {
  border-left-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.border-dotted {
  border-style: dotted;
}

.border-double {
  border-style: double;
}

.border-none {
  border-style: none;
}

.border-trans {
  border-color: transparent;
}

.border-white {
  border-color: #ffffff;
}

.border-black {
  border-color: #000000;
}

.border-red {
  border-color: #FA5555;
}

.border-green {
  border-color: #2ECC71;
}

.border-yellow {
  border-color: #F4A72C;
}

.border-brand {
  border-color: #2e5bff;
}

.border-gray-100 {
  border-color: #ECECEC;
}

.bg-trans {
  background-color: transparent;
}

.bg-white {
  background-color: #ffffff;
}

.bg-black {
  background-color: #000000;
}

.bg-red {
  background-color: #FA5555;
}

.bg-green {
  background-color: #2ECC71;
}

.bg-yellow {
  background-color: #F4A72C;
}

.bg-brand {
  background-color: #2e5bff;
}

.bg-gray-700 {
  background-color: #999999;
}

.bg-gray-800 {
  background-color: #ececec;
}

.bg-gray-900 {
  background-color: #f7f7f7;
}

.bg-gray-alpha4 {
  background-color: rgba(0, 0, 0, 0.4);
}

.decoration-slice {
  -webkit-box-decoration-break: slice;
          box-decoration-break: slice;
}

.decoration-clone {
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
}

.bg-origin-border {
  background-origin: border-box;
}

.bg-origin-padding {
  background-origin: padding-box;
}

.bg-origin-content {
  background-origin: content-box;
}

.p-10 {
  padding: 5px;
}

.p-15 {
  padding: 7.5px;
}

.p-20 {
  padding: 10px;
}

.p-30 {
  padding: 15px;
}

.p-40 {
  padding: 20px;
}

.p-50 {
  padding: 25px;
}

.p-60 {
  padding: 30px;
}

.p-64 {
  padding: 32px;
}

.p-92 {
  padding: 46px;
}

.px-10 {
  padding-left: 5px;
  padding-right: 5px;
}

.px-15 {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.px-20 {
  padding-left: 10px;
  padding-right: 10px;
}

.px-30 {
  padding-left: 15px;
  padding-right: 15px;
}

.px-40 {
  padding-left: 20px;
  padding-right: 20px;
}

.px-50 {
  padding-left: 25px;
  padding-right: 25px;
}

.px-60 {
  padding-left: 30px;
  padding-right: 30px;
}

.px-64 {
  padding-left: 32px;
  padding-right: 32px;
}

.px-92 {
  padding-left: 46px;
  padding-right: 46px;
}

.py-10 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py-15 {
  padding-top: 7.5px;
  padding-bottom: 7.5px;
}

.py-20 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-30 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.py-40 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-50 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.py-60 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.py-64 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.py-92 {
  padding-top: 46px;
  padding-bottom: 46px;
}

.pt-10 {
  padding-top: 5px;
}

.pt-15 {
  padding-top: 7.5px;
}

.pt-20 {
  padding-top: 10px;
}

.pt-30 {
  padding-top: 15px;
}

.pt-40 {
  padding-top: 20px;
}

.pt-50 {
  padding-top: 25px;
}

.pt-60 {
  padding-top: 30px;
}

.pt-64 {
  padding-top: 32px;
}

.pt-92 {
  padding-top: 46px;
}

.pr-10 {
  padding-right: 5px;
}

.pr-15 {
  padding-right: 7.5px;
}

.pr-20 {
  padding-right: 10px;
}

.pr-30 {
  padding-right: 15px;
}

.pr-40 {
  padding-right: 20px;
}

.pr-50 {
  padding-right: 25px;
}

.pr-60 {
  padding-right: 30px;
}

.pr-64 {
  padding-right: 32px;
}

.pr-92 {
  padding-right: 46px;
}

.pb-10 {
  padding-bottom: 5px;
}

.pb-15 {
  padding-bottom: 7.5px;
}

.pb-20 {
  padding-bottom: 10px;
}

.pb-30 {
  padding-bottom: 15px;
}

.pb-40 {
  padding-bottom: 20px;
}

.pb-50 {
  padding-bottom: 25px;
}

.pb-60 {
  padding-bottom: 30px;
}

.pb-64 {
  padding-bottom: 32px;
}

.pb-92 {
  padding-bottom: 46px;
}

.pl-10 {
  padding-left: 5px;
}

.pl-15 {
  padding-left: 7.5px;
}

.pl-20 {
  padding-left: 10px;
}

.pl-30 {
  padding-left: 15px;
}

.pl-40 {
  padding-left: 20px;
}

.pl-50 {
  padding-left: 25px;
}

.pl-60 {
  padding-left: 30px;
}

.pl-64 {
  padding-left: 32px;
}

.pl-92 {
  padding-left: 46px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-20 {
  font-size: 10px;
}

.text-24 {
  font-size: 12px;
}

.text-28 {
  font-size: 14px;
}

.text-30 {
  font-size: 15px;
}

.text-32 {
  font-size: 16px;
}

.text-40 {
  font-size: 20px;
}

.text-52 {
  font-size: 26px;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.leading-64 {
  line-height: 32px;
}

.leading-88 {
  line-height: 44px;
}

.leading-92 {
  line-height: 46px;
}

.leading-none {
  line-height: 1;
}

.leading-tight {
  line-height: 1.25;
}

.leading-snug {
  line-height: 1.375;
}

.leading-normal {
  line-height: 1.5;
}

.text-trans {
  color: transparent;
}

.text-white {
  color: #ffffff;
}

.text-black {
  color: #000000;
}

.text-red {
  color: #FA5555;
}

.text-green {
  color: #2ECC71;
}

.text-yellow {
  color: #F4A72C;
}

.text-brand {
  color: #2e5bff;
}

.text-gray-100 {
  color: #1D1D1D;
}

.text-gray-300 {
  color: #3D3D3D;
}

.text-gray-500 {
  color: #666666;
}

.text-gray-600 {
  color: #8A959E;
}

.text-gray-700 {
  color: #999999;
}

.caret-trans {
  caret-color: transparent;
}

.caret-white {
  caret-color: #ffffff;
}

.caret-black {
  caret-color: #000000;
}

.caret-red {
  caret-color: #FA5555;
}

.caret-green {
  caret-color: #2ECC71;
}

.caret-yellow {
  caret-color: #F4A72C;
}

.caret-brand {
  caret-color: #2e5bff;
}

.bg-blend-normal {
  background-blend-mode: normal;
}

.bg-blend-multiply {
  background-blend-mode: multiply;
}

.bg-blend-screen {
  background-blend-mode: screen;
}

.bg-blend-overlay {
  background-blend-mode: overlay;
}

.bg-blend-darken {
  background-blend-mode: darken;
}

.bg-blend-lighten {
  background-blend-mode: lighten;
}

.bg-blend-color-dodge {
  background-blend-mode: color-dodge;
}

.bg-blend-color-burn {
  background-blend-mode: color-burn;
}

.bg-blend-hard-light {
  background-blend-mode: hard-light;
}

.bg-blend-soft-light {
  background-blend-mode: soft-light;
}

.bg-blend-difference {
  background-blend-mode: difference;
}

.bg-blend-exclusion {
  background-blend-mode: exclusion;
}

.bg-blend-hue {
  background-blend-mode: hue;
}

.bg-blend-saturation {
  background-blend-mode: saturation;
}

.bg-blend-color {
  background-blend-mode: color;
}

.bg-blend-luminosity {
  background-blend-mode: luminosity;
}

.mix-blend-normal {
  mix-blend-mode: normal;
}

.mix-blend-multiply {
  mix-blend-mode: multiply;
}

.mix-blend-screen {
  mix-blend-mode: screen;
}

.mix-blend-overlay {
  mix-blend-mode: overlay;
}

.mix-blend-darken {
  mix-blend-mode: darken;
}

.mix-blend-lighten {
  mix-blend-mode: lighten;
}

.mix-blend-color-dodge {
  mix-blend-mode: color-dodge;
}

.mix-blend-color-burn {
  mix-blend-mode: color-burn;
}

.mix-blend-hard-light {
  mix-blend-mode: hard-light;
}

.mix-blend-soft-light {
  mix-blend-mode: soft-light;
}

.mix-blend-difference {
  mix-blend-mode: difference;
}

.mix-blend-exclusion {
  mix-blend-mode: exclusion;
}

.mix-blend-hue {
  mix-blend-mode: hue;
}

.mix-blend-saturation {
  mix-blend-mode: saturation;
}

.mix-blend-color {
  mix-blend-mode: color;
}

.mix-blend-luminosity {
  mix-blend-mode: luminosity;
}

*, ::before, ::after {
  --tw-shadow: 0 0 #0000;
}

.shadow-dark {
  --tw-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

*, ::before, ::after {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-4 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-8 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-within\:ring-0:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-within\:ring-1:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-within\:ring-2:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-within\:ring-4:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-within\:ring-8:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-within\:ring:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-1:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-4:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-8:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-inset {
  --tw-ring-inset: inset;
}

.focus-within\:ring-inset:focus-within {
  --tw-ring-inset: inset;
}

.focus\:ring-inset:focus {
  --tw-ring-inset: inset;
}

.ring-trans {
  --tw-ring-color: transparent;
}

.ring-white {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
}

.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
}

.ring-red {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 85, 85, var(--tw-ring-opacity));
}

.ring-green {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(46, 204, 113, var(--tw-ring-opacity));
}

.ring-yellow {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 167, 44, var(--tw-ring-opacity));
}

.ring-brand {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(46, 91, 255, var(--tw-ring-opacity));
}

.focus-within\:ring-trans:focus-within {
  --tw-ring-color: transparent;
}

.focus-within\:ring-white:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
}

.focus-within\:ring-black:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
}

.focus-within\:ring-red:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 85, 85, var(--tw-ring-opacity));
}

.focus-within\:ring-green:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(46, 204, 113, var(--tw-ring-opacity));
}

.focus-within\:ring-yellow:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 167, 44, var(--tw-ring-opacity));
}

.focus-within\:ring-brand:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(46, 91, 255, var(--tw-ring-opacity));
}

.focus\:ring-trans:focus {
  --tw-ring-color: transparent;
}

.focus\:ring-white:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
}

.focus\:ring-black:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
}

.focus\:ring-red:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 85, 85, var(--tw-ring-opacity));
}

.focus\:ring-green:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(46, 204, 113, var(--tw-ring-opacity));
}

.focus\:ring-yellow:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 167, 44, var(--tw-ring-opacity));
}

.focus\:ring-brand:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(46, 91, 255, var(--tw-ring-opacity));
}

.ring-opacity-0 {
  --tw-ring-opacity: 0;
}

.ring-opacity-5 {
  --tw-ring-opacity: 0.05;
}

.ring-opacity-10 {
  --tw-ring-opacity: 0.1;
}

.ring-opacity-20 {
  --tw-ring-opacity: 0.2;
}

.ring-opacity-25 {
  --tw-ring-opacity: 0.25;
}

.ring-opacity-30 {
  --tw-ring-opacity: 0.3;
}

.ring-opacity-40 {
  --tw-ring-opacity: 0.4;
}

.ring-opacity-50 {
  --tw-ring-opacity: 0.5;
}

.ring-opacity-60 {
  --tw-ring-opacity: 0.6;
}

.ring-opacity-70 {
  --tw-ring-opacity: 0.7;
}

.ring-opacity-75 {
  --tw-ring-opacity: 0.75;
}

.ring-opacity-80 {
  --tw-ring-opacity: 0.8;
}

.ring-opacity-90 {
  --tw-ring-opacity: 0.9;
}

.ring-opacity-95 {
  --tw-ring-opacity: 0.95;
}

.ring-opacity-100 {
  --tw-ring-opacity: 1;
}

.focus-within\:ring-opacity-0:focus-within {
  --tw-ring-opacity: 0;
}

.focus-within\:ring-opacity-5:focus-within {
  --tw-ring-opacity: 0.05;
}

.focus-within\:ring-opacity-10:focus-within {
  --tw-ring-opacity: 0.1;
}

.focus-within\:ring-opacity-20:focus-within {
  --tw-ring-opacity: 0.2;
}

.focus-within\:ring-opacity-25:focus-within {
  --tw-ring-opacity: 0.25;
}

.focus-within\:ring-opacity-30:focus-within {
  --tw-ring-opacity: 0.3;
}

.focus-within\:ring-opacity-40:focus-within {
  --tw-ring-opacity: 0.4;
}

.focus-within\:ring-opacity-50:focus-within {
  --tw-ring-opacity: 0.5;
}

.focus-within\:ring-opacity-60:focus-within {
  --tw-ring-opacity: 0.6;
}

.focus-within\:ring-opacity-70:focus-within {
  --tw-ring-opacity: 0.7;
}

.focus-within\:ring-opacity-75:focus-within {
  --tw-ring-opacity: 0.75;
}

.focus-within\:ring-opacity-80:focus-within {
  --tw-ring-opacity: 0.8;
}

.focus-within\:ring-opacity-90:focus-within {
  --tw-ring-opacity: 0.9;
}

.focus-within\:ring-opacity-95:focus-within {
  --tw-ring-opacity: 0.95;
}

.focus-within\:ring-opacity-100:focus-within {
  --tw-ring-opacity: 1;
}

.focus\:ring-opacity-0:focus {
  --tw-ring-opacity: 0;
}

.focus\:ring-opacity-5:focus {
  --tw-ring-opacity: 0.05;
}

.focus\:ring-opacity-10:focus {
  --tw-ring-opacity: 0.1;
}

.focus\:ring-opacity-20:focus {
  --tw-ring-opacity: 0.2;
}

.focus\:ring-opacity-25:focus {
  --tw-ring-opacity: 0.25;
}

.focus\:ring-opacity-30:focus {
  --tw-ring-opacity: 0.3;
}

.focus\:ring-opacity-40:focus {
  --tw-ring-opacity: 0.4;
}

.focus\:ring-opacity-50:focus {
  --tw-ring-opacity: 0.5;
}

.focus\:ring-opacity-60:focus {
  --tw-ring-opacity: 0.6;
}

.focus\:ring-opacity-70:focus {
  --tw-ring-opacity: 0.7;
}

.focus\:ring-opacity-75:focus {
  --tw-ring-opacity: 0.75;
}

.focus\:ring-opacity-80:focus {
  --tw-ring-opacity: 0.8;
}

.focus\:ring-opacity-90:focus {
  --tw-ring-opacity: 0.9;
}

.focus\:ring-opacity-95:focus {
  --tw-ring-opacity: 0.95;
}

.focus\:ring-opacity-100:focus {
  --tw-ring-opacity: 1;
}

.ring-offset-0 {
  --tw-ring-offset-width: 0px;
}

.ring-offset-1 {
  --tw-ring-offset-width: 1px;
}

.ring-offset-2 {
  --tw-ring-offset-width: 2px;
}

.ring-offset-4 {
  --tw-ring-offset-width: 4px;
}

.ring-offset-8 {
  --tw-ring-offset-width: 8px;
}

.focus-within\:ring-offset-0:focus-within {
  --tw-ring-offset-width: 0px;
}

.focus-within\:ring-offset-1:focus-within {
  --tw-ring-offset-width: 1px;
}

.focus-within\:ring-offset-2:focus-within {
  --tw-ring-offset-width: 2px;
}

.focus-within\:ring-offset-4:focus-within {
  --tw-ring-offset-width: 4px;
}

.focus-within\:ring-offset-8:focus-within {
  --tw-ring-offset-width: 8px;
}

.focus\:ring-offset-0:focus {
  --tw-ring-offset-width: 0px;
}

.focus\:ring-offset-1:focus {
  --tw-ring-offset-width: 1px;
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.focus\:ring-offset-4:focus {
  --tw-ring-offset-width: 4px;
}

.focus\:ring-offset-8:focus {
  --tw-ring-offset-width: 8px;
}

.ring-offset-trans {
  --tw-ring-offset-color: transparent;
}

.ring-offset-white {
  --tw-ring-offset-color: #ffffff;
}

.ring-offset-black {
  --tw-ring-offset-color: #000000;
}

.ring-offset-red {
  --tw-ring-offset-color: #FA5555;
}

.ring-offset-green {
  --tw-ring-offset-color: #2ECC71;
}

.ring-offset-yellow {
  --tw-ring-offset-color: #F4A72C;
}

.ring-offset-brand {
  --tw-ring-offset-color: #2e5bff;
}

.focus-within\:ring-offset-trans:focus-within {
  --tw-ring-offset-color: transparent;
}

.focus-within\:ring-offset-white:focus-within {
  --tw-ring-offset-color: #ffffff;
}

.focus-within\:ring-offset-black:focus-within {
  --tw-ring-offset-color: #000000;
}

.focus-within\:ring-offset-red:focus-within {
  --tw-ring-offset-color: #FA5555;
}

.focus-within\:ring-offset-green:focus-within {
  --tw-ring-offset-color: #2ECC71;
}

.focus-within\:ring-offset-yellow:focus-within {
  --tw-ring-offset-color: #F4A72C;
}

.focus-within\:ring-offset-brand:focus-within {
  --tw-ring-offset-color: #2e5bff;
}

.focus\:ring-offset-trans:focus {
  --tw-ring-offset-color: transparent;
}

.focus\:ring-offset-white:focus {
  --tw-ring-offset-color: #ffffff;
}

.focus\:ring-offset-black:focus {
  --tw-ring-offset-color: #000000;
}

.focus\:ring-offset-red:focus {
  --tw-ring-offset-color: #FA5555;
}

.focus\:ring-offset-green:focus {
  --tw-ring-offset-color: #2ECC71;
}

.focus\:ring-offset-yellow:focus {
  --tw-ring-offset-color: #F4A72C;
}

.focus\:ring-offset-brand:focus {
  --tw-ring-offset-color: #2e5bff;
}

.filter {
  --tw-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter-none {
  -webkit-filter: none;
          filter: none;
}

.blur-0 {
  --tw-blur: blur(0);
}

.blur-none {
  --tw-blur: blur(0);
}

.blur-sm {
  --tw-blur: blur(4px);
}

.blur {
  --tw-blur: blur(8px);
}

.blur-md {
  --tw-blur: blur(12px);
}

.blur-lg {
  --tw-blur: blur(16px);
}

.blur-xl {
  --tw-blur: blur(24px);
}

.blur-2xl {
  --tw-blur: blur(40px);
}

.blur-3xl {
  --tw-blur: blur(64px);
}

.brightness-0 {
  --tw-brightness: brightness(0);
}

.brightness-50 {
  --tw-brightness: brightness(.5);
}

.brightness-75 {
  --tw-brightness: brightness(.75);
}

.brightness-90 {
  --tw-brightness: brightness(.9);
}

.brightness-95 {
  --tw-brightness: brightness(.95);
}

.brightness-100 {
  --tw-brightness: brightness(1);
}

.brightness-105 {
  --tw-brightness: brightness(1.05);
}

.brightness-110 {
  --tw-brightness: brightness(1.1);
}

.brightness-125 {
  --tw-brightness: brightness(1.25);
}

.brightness-150 {
  --tw-brightness: brightness(1.5);
}

.brightness-200 {
  --tw-brightness: brightness(2);
}

.contrast-0 {
  --tw-contrast: contrast(0);
}

.contrast-50 {
  --tw-contrast: contrast(.5);
}

.contrast-75 {
  --tw-contrast: contrast(.75);
}

.contrast-100 {
  --tw-contrast: contrast(1);
}

.contrast-125 {
  --tw-contrast: contrast(1.25);
}

.contrast-150 {
  --tw-contrast: contrast(1.5);
}

.contrast-200 {
  --tw-contrast: contrast(2);
}

.drop-shadow-sm {
  --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05));
}

.drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06));
}

.drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
}

.drop-shadow-lg {
  --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1));
}

.drop-shadow-xl {
  --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08));
}

.drop-shadow-2xl {
  --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15));
}

.drop-shadow-none {
  --tw-drop-shadow: drop-shadow(0 0 #0000);
}

.grayscale-0 {
  --tw-grayscale: grayscale(0);
}

.grayscale {
  --tw-grayscale: grayscale(100%);
}

.hue-rotate-0 {
  --tw-hue-rotate: hue-rotate(0deg);
}

.hue-rotate-15 {
  --tw-hue-rotate: hue-rotate(15deg);
}

.hue-rotate-30 {
  --tw-hue-rotate: hue-rotate(30deg);
}

.hue-rotate-60 {
  --tw-hue-rotate: hue-rotate(60deg);
}

.hue-rotate-90 {
  --tw-hue-rotate: hue-rotate(90deg);
}

.hue-rotate-180 {
  --tw-hue-rotate: hue-rotate(180deg);
}

.-hue-rotate-180 {
  --tw-hue-rotate: hue-rotate(-180deg);
}

.-hue-rotate-90 {
  --tw-hue-rotate: hue-rotate(-90deg);
}

.-hue-rotate-60 {
  --tw-hue-rotate: hue-rotate(-60deg);
}

.-hue-rotate-30 {
  --tw-hue-rotate: hue-rotate(-30deg);
}

.-hue-rotate-15 {
  --tw-hue-rotate: hue-rotate(-15deg);
}

.invert-0 {
  --tw-invert: invert(0);
}

.invert {
  --tw-invert: invert(100%);
}

.saturate-0 {
  --tw-saturate: saturate(0);
}

.saturate-50 {
  --tw-saturate: saturate(.5);
}

.saturate-100 {
  --tw-saturate: saturate(1);
}

.saturate-150 {
  --tw-saturate: saturate(1.5);
}

.saturate-200 {
  --tw-saturate: saturate(2);
}

.sepia-0 {
  --tw-sepia: sepia(0);
}

.sepia {
  --tw-sepia: sepia(100%);
}

.backdrop-filter {
  --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-filter-none {
  -webkit-backdrop-filter: none;
          backdrop-filter: none;
}

.backdrop-blur-0 {
  --tw-backdrop-blur: blur(0);
}

.backdrop-blur-none {
  --tw-backdrop-blur: blur(0);
}

.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
}

.backdrop-blur {
  --tw-backdrop-blur: blur(8px);
}

.backdrop-blur-md {
  --tw-backdrop-blur: blur(12px);
}

.backdrop-blur-lg {
  --tw-backdrop-blur: blur(16px);
}

.backdrop-blur-xl {
  --tw-backdrop-blur: blur(24px);
}

.backdrop-blur-2xl {
  --tw-backdrop-blur: blur(40px);
}

.backdrop-blur-3xl {
  --tw-backdrop-blur: blur(64px);
}

.backdrop-brightness-0 {
  --tw-backdrop-brightness: brightness(0);
}

.backdrop-brightness-50 {
  --tw-backdrop-brightness: brightness(.5);
}

.backdrop-brightness-75 {
  --tw-backdrop-brightness: brightness(.75);
}

.backdrop-brightness-90 {
  --tw-backdrop-brightness: brightness(.9);
}

.backdrop-brightness-95 {
  --tw-backdrop-brightness: brightness(.95);
}

.backdrop-brightness-100 {
  --tw-backdrop-brightness: brightness(1);
}

.backdrop-brightness-105 {
  --tw-backdrop-brightness: brightness(1.05);
}

.backdrop-brightness-110 {
  --tw-backdrop-brightness: brightness(1.1);
}

.backdrop-brightness-125 {
  --tw-backdrop-brightness: brightness(1.25);
}

.backdrop-brightness-150 {
  --tw-backdrop-brightness: brightness(1.5);
}

.backdrop-brightness-200 {
  --tw-backdrop-brightness: brightness(2);
}

.backdrop-contrast-0 {
  --tw-backdrop-contrast: contrast(0);
}

.backdrop-contrast-50 {
  --tw-backdrop-contrast: contrast(.5);
}

.backdrop-contrast-75 {
  --tw-backdrop-contrast: contrast(.75);
}

.backdrop-contrast-100 {
  --tw-backdrop-contrast: contrast(1);
}

.backdrop-contrast-125 {
  --tw-backdrop-contrast: contrast(1.25);
}

.backdrop-contrast-150 {
  --tw-backdrop-contrast: contrast(1.5);
}

.backdrop-contrast-200 {
  --tw-backdrop-contrast: contrast(2);
}

.backdrop-grayscale-0 {
  --tw-backdrop-grayscale: grayscale(0);
}

.backdrop-grayscale {
  --tw-backdrop-grayscale: grayscale(100%);
}

.backdrop-hue-rotate-0 {
  --tw-backdrop-hue-rotate: hue-rotate(0deg);
}

.backdrop-hue-rotate-15 {
  --tw-backdrop-hue-rotate: hue-rotate(15deg);
}

.backdrop-hue-rotate-30 {
  --tw-backdrop-hue-rotate: hue-rotate(30deg);
}

.backdrop-hue-rotate-60 {
  --tw-backdrop-hue-rotate: hue-rotate(60deg);
}

.backdrop-hue-rotate-90 {
  --tw-backdrop-hue-rotate: hue-rotate(90deg);
}

.backdrop-hue-rotate-180 {
  --tw-backdrop-hue-rotate: hue-rotate(180deg);
}

.-backdrop-hue-rotate-180 {
  --tw-backdrop-hue-rotate: hue-rotate(-180deg);
}

.-backdrop-hue-rotate-90 {
  --tw-backdrop-hue-rotate: hue-rotate(-90deg);
}

.-backdrop-hue-rotate-60 {
  --tw-backdrop-hue-rotate: hue-rotate(-60deg);
}

.-backdrop-hue-rotate-30 {
  --tw-backdrop-hue-rotate: hue-rotate(-30deg);
}

.-backdrop-hue-rotate-15 {
  --tw-backdrop-hue-rotate: hue-rotate(-15deg);
}

.backdrop-invert-0 {
  --tw-backdrop-invert: invert(0);
}

.backdrop-invert {
  --tw-backdrop-invert: invert(100%);
}

.backdrop-opacity-0 {
  --tw-backdrop-opacity: opacity(0);
}

.backdrop-opacity-5 {
  --tw-backdrop-opacity: opacity(0.05);
}

.backdrop-opacity-10 {
  --tw-backdrop-opacity: opacity(0.1);
}

.backdrop-opacity-20 {
  --tw-backdrop-opacity: opacity(0.2);
}

.backdrop-opacity-25 {
  --tw-backdrop-opacity: opacity(0.25);
}

.backdrop-opacity-30 {
  --tw-backdrop-opacity: opacity(0.3);
}

.backdrop-opacity-40 {
  --tw-backdrop-opacity: opacity(0.4);
}

.backdrop-opacity-50 {
  --tw-backdrop-opacity: opacity(0.5);
}

.backdrop-opacity-60 {
  --tw-backdrop-opacity: opacity(0.6);
}

.backdrop-opacity-70 {
  --tw-backdrop-opacity: opacity(0.7);
}

.backdrop-opacity-75 {
  --tw-backdrop-opacity: opacity(0.75);
}

.backdrop-opacity-80 {
  --tw-backdrop-opacity: opacity(0.8);
}

.backdrop-opacity-90 {
  --tw-backdrop-opacity: opacity(0.9);
}

.backdrop-opacity-95 {
  --tw-backdrop-opacity: opacity(0.95);
}

.backdrop-opacity-100 {
  --tw-backdrop-opacity: opacity(1);
}

.backdrop-saturate-0 {
  --tw-backdrop-saturate: saturate(0);
}

.backdrop-saturate-50 {
  --tw-backdrop-saturate: saturate(.5);
}

.backdrop-saturate-100 {
  --tw-backdrop-saturate: saturate(1);
}

.backdrop-saturate-150 {
  --tw-backdrop-saturate: saturate(1.5);
}

.backdrop-saturate-200 {
  --tw-backdrop-saturate: saturate(2);
}

.backdrop-sepia-0 {
  --tw-backdrop-sepia: sepia(0);
}

.backdrop-sepia {
  --tw-backdrop-sepia: sepia(100%);
}

.content-none {
  content: none;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 640px) {
  .sm\:isolate {
    isolation: isolate;
  }

  .sm\:isolation-auto {
    isolation: auto;
  }

  .sm\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .sm\:overflow-ellipsis {
    text-overflow: ellipsis;
  }

  .sm\:overflow-clip {
    text-overflow: clip;
  }

  .sm\:decoration-slice {
    -webkit-box-decoration-break: slice;
            box-decoration-break: slice;
  }

  .sm\:decoration-clone {
    -webkit-box-decoration-break: clone;
            box-decoration-break: clone;
  }

  .sm\:bg-origin-border {
    background-origin: border-box;
  }

  .sm\:bg-origin-padding {
    background-origin: padding-box;
  }

  .sm\:bg-origin-content {
    background-origin: content-box;
  }

  .sm\:bg-blend-normal {
    background-blend-mode: normal;
  }

  .sm\:bg-blend-multiply {
    background-blend-mode: multiply;
  }

  .sm\:bg-blend-screen {
    background-blend-mode: screen;
  }

  .sm\:bg-blend-overlay {
    background-blend-mode: overlay;
  }

  .sm\:bg-blend-darken {
    background-blend-mode: darken;
  }

  .sm\:bg-blend-lighten {
    background-blend-mode: lighten;
  }

  .sm\:bg-blend-color-dodge {
    background-blend-mode: color-dodge;
  }

  .sm\:bg-blend-color-burn {
    background-blend-mode: color-burn;
  }

  .sm\:bg-blend-hard-light {
    background-blend-mode: hard-light;
  }

  .sm\:bg-blend-soft-light {
    background-blend-mode: soft-light;
  }

  .sm\:bg-blend-difference {
    background-blend-mode: difference;
  }

  .sm\:bg-blend-exclusion {
    background-blend-mode: exclusion;
  }

  .sm\:bg-blend-hue {
    background-blend-mode: hue;
  }

  .sm\:bg-blend-saturation {
    background-blend-mode: saturation;
  }

  .sm\:bg-blend-color {
    background-blend-mode: color;
  }

  .sm\:bg-blend-luminosity {
    background-blend-mode: luminosity;
  }

  .sm\:mix-blend-normal {
    mix-blend-mode: normal;
  }

  .sm\:mix-blend-multiply {
    mix-blend-mode: multiply;
  }

  .sm\:mix-blend-screen {
    mix-blend-mode: screen;
  }

  .sm\:mix-blend-overlay {
    mix-blend-mode: overlay;
  }

  .sm\:mix-blend-darken {
    mix-blend-mode: darken;
  }

  .sm\:mix-blend-lighten {
    mix-blend-mode: lighten;
  }

  .sm\:mix-blend-color-dodge {
    mix-blend-mode: color-dodge;
  }

  .sm\:mix-blend-color-burn {
    mix-blend-mode: color-burn;
  }

  .sm\:mix-blend-hard-light {
    mix-blend-mode: hard-light;
  }

  .sm\:mix-blend-soft-light {
    mix-blend-mode: soft-light;
  }

  .sm\:mix-blend-difference {
    mix-blend-mode: difference;
  }

  .sm\:mix-blend-exclusion {
    mix-blend-mode: exclusion;
  }

  .sm\:mix-blend-hue {
    mix-blend-mode: hue;
  }

  .sm\:mix-blend-saturation {
    mix-blend-mode: saturation;
  }

  .sm\:mix-blend-color {
    mix-blend-mode: color;
  }

  .sm\:mix-blend-luminosity {
    mix-blend-mode: luminosity;
  }

  .sm\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus-within\:ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus-within\:ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus-within\:ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus-within\:ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus-within\:ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus-within\:ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus\:ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus\:ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:ring-inset {
    --tw-ring-inset: inset;
  }

  .sm\:focus-within\:ring-inset:focus-within {
    --tw-ring-inset: inset;
  }

  .sm\:focus\:ring-inset:focus {
    --tw-ring-inset: inset;
  }

  .sm\:ring-trans {
    --tw-ring-color: transparent;
  }

  .sm\:ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .sm\:ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .sm\:ring-red {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 85, 85, var(--tw-ring-opacity));
  }

  .sm\:ring-green {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 204, 113, var(--tw-ring-opacity));
  }

  .sm\:ring-yellow {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 167, 44, var(--tw-ring-opacity));
  }

  .sm\:ring-brand {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 91, 255, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-trans:focus-within {
    --tw-ring-color: transparent;
  }

  .sm\:focus-within\:ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-red:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 85, 85, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-green:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 204, 113, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-yellow:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 167, 44, var(--tw-ring-opacity));
  }

  .sm\:focus-within\:ring-brand:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 91, 255, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-trans:focus {
    --tw-ring-color: transparent;
  }

  .sm\:focus\:ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-red:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 85, 85, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-green:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 204, 113, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-yellow:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 167, 44, var(--tw-ring-opacity));
  }

  .sm\:focus\:ring-brand:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 91, 255, var(--tw-ring-opacity));
  }

  .sm\:ring-opacity-0 {
    --tw-ring-opacity: 0;
  }

  .sm\:ring-opacity-5 {
    --tw-ring-opacity: 0.05;
  }

  .sm\:ring-opacity-10 {
    --tw-ring-opacity: 0.1;
  }

  .sm\:ring-opacity-20 {
    --tw-ring-opacity: 0.2;
  }

  .sm\:ring-opacity-25 {
    --tw-ring-opacity: 0.25;
  }

  .sm\:ring-opacity-30 {
    --tw-ring-opacity: 0.3;
  }

  .sm\:ring-opacity-40 {
    --tw-ring-opacity: 0.4;
  }

  .sm\:ring-opacity-50 {
    --tw-ring-opacity: 0.5;
  }

  .sm\:ring-opacity-60 {
    --tw-ring-opacity: 0.6;
  }

  .sm\:ring-opacity-70 {
    --tw-ring-opacity: 0.7;
  }

  .sm\:ring-opacity-75 {
    --tw-ring-opacity: 0.75;
  }

  .sm\:ring-opacity-80 {
    --tw-ring-opacity: 0.8;
  }

  .sm\:ring-opacity-90 {
    --tw-ring-opacity: 0.9;
  }

  .sm\:ring-opacity-95 {
    --tw-ring-opacity: 0.95;
  }

  .sm\:ring-opacity-100 {
    --tw-ring-opacity: 1;
  }

  .sm\:focus-within\:ring-opacity-0:focus-within {
    --tw-ring-opacity: 0;
  }

  .sm\:focus-within\:ring-opacity-5:focus-within {
    --tw-ring-opacity: 0.05;
  }

  .sm\:focus-within\:ring-opacity-10:focus-within {
    --tw-ring-opacity: 0.1;
  }

  .sm\:focus-within\:ring-opacity-20:focus-within {
    --tw-ring-opacity: 0.2;
  }

  .sm\:focus-within\:ring-opacity-25:focus-within {
    --tw-ring-opacity: 0.25;
  }

  .sm\:focus-within\:ring-opacity-30:focus-within {
    --tw-ring-opacity: 0.3;
  }

  .sm\:focus-within\:ring-opacity-40:focus-within {
    --tw-ring-opacity: 0.4;
  }

  .sm\:focus-within\:ring-opacity-50:focus-within {
    --tw-ring-opacity: 0.5;
  }

  .sm\:focus-within\:ring-opacity-60:focus-within {
    --tw-ring-opacity: 0.6;
  }

  .sm\:focus-within\:ring-opacity-70:focus-within {
    --tw-ring-opacity: 0.7;
  }

  .sm\:focus-within\:ring-opacity-75:focus-within {
    --tw-ring-opacity: 0.75;
  }

  .sm\:focus-within\:ring-opacity-80:focus-within {
    --tw-ring-opacity: 0.8;
  }

  .sm\:focus-within\:ring-opacity-90:focus-within {
    --tw-ring-opacity: 0.9;
  }

  .sm\:focus-within\:ring-opacity-95:focus-within {
    --tw-ring-opacity: 0.95;
  }

  .sm\:focus-within\:ring-opacity-100:focus-within {
    --tw-ring-opacity: 1;
  }

  .sm\:focus\:ring-opacity-0:focus {
    --tw-ring-opacity: 0;
  }

  .sm\:focus\:ring-opacity-5:focus {
    --tw-ring-opacity: 0.05;
  }

  .sm\:focus\:ring-opacity-10:focus {
    --tw-ring-opacity: 0.1;
  }

  .sm\:focus\:ring-opacity-20:focus {
    --tw-ring-opacity: 0.2;
  }

  .sm\:focus\:ring-opacity-25:focus {
    --tw-ring-opacity: 0.25;
  }

  .sm\:focus\:ring-opacity-30:focus {
    --tw-ring-opacity: 0.3;
  }

  .sm\:focus\:ring-opacity-40:focus {
    --tw-ring-opacity: 0.4;
  }

  .sm\:focus\:ring-opacity-50:focus {
    --tw-ring-opacity: 0.5;
  }

  .sm\:focus\:ring-opacity-60:focus {
    --tw-ring-opacity: 0.6;
  }

  .sm\:focus\:ring-opacity-70:focus {
    --tw-ring-opacity: 0.7;
  }

  .sm\:focus\:ring-opacity-75:focus {
    --tw-ring-opacity: 0.75;
  }

  .sm\:focus\:ring-opacity-80:focus {
    --tw-ring-opacity: 0.8;
  }

  .sm\:focus\:ring-opacity-90:focus {
    --tw-ring-opacity: 0.9;
  }

  .sm\:focus\:ring-opacity-95:focus {
    --tw-ring-opacity: 0.95;
  }

  .sm\:focus\:ring-opacity-100:focus {
    --tw-ring-opacity: 1;
  }

  .sm\:ring-offset-0 {
    --tw-ring-offset-width: 0px;
  }

  .sm\:ring-offset-1 {
    --tw-ring-offset-width: 1px;
  }

  .sm\:ring-offset-2 {
    --tw-ring-offset-width: 2px;
  }

  .sm\:ring-offset-4 {
    --tw-ring-offset-width: 4px;
  }

  .sm\:ring-offset-8 {
    --tw-ring-offset-width: 8px;
  }

  .sm\:focus-within\:ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px;
  }

  .sm\:focus-within\:ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px;
  }

  .sm\:focus-within\:ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px;
  }

  .sm\:focus-within\:ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px;
  }

  .sm\:focus-within\:ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px;
  }

  .sm\:focus\:ring-offset-0:focus {
    --tw-ring-offset-width: 0px;
  }

  .sm\:focus\:ring-offset-1:focus {
    --tw-ring-offset-width: 1px;
  }

  .sm\:focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
  }

  .sm\:focus\:ring-offset-4:focus {
    --tw-ring-offset-width: 4px;
  }

  .sm\:focus\:ring-offset-8:focus {
    --tw-ring-offset-width: 8px;
  }

  .sm\:ring-offset-trans {
    --tw-ring-offset-color: transparent;
  }

  .sm\:ring-offset-white {
    --tw-ring-offset-color: #ffffff;
  }

  .sm\:ring-offset-black {
    --tw-ring-offset-color: #000000;
  }

  .sm\:ring-offset-red {
    --tw-ring-offset-color: #FA5555;
  }

  .sm\:ring-offset-green {
    --tw-ring-offset-color: #2ECC71;
  }

  .sm\:ring-offset-yellow {
    --tw-ring-offset-color: #F4A72C;
  }

  .sm\:ring-offset-brand {
    --tw-ring-offset-color: #2e5bff;
  }

  .sm\:focus-within\:ring-offset-trans:focus-within {
    --tw-ring-offset-color: transparent;
  }

  .sm\:focus-within\:ring-offset-white:focus-within {
    --tw-ring-offset-color: #ffffff;
  }

  .sm\:focus-within\:ring-offset-black:focus-within {
    --tw-ring-offset-color: #000000;
  }

  .sm\:focus-within\:ring-offset-red:focus-within {
    --tw-ring-offset-color: #FA5555;
  }

  .sm\:focus-within\:ring-offset-green:focus-within {
    --tw-ring-offset-color: #2ECC71;
  }

  .sm\:focus-within\:ring-offset-yellow:focus-within {
    --tw-ring-offset-color: #F4A72C;
  }

  .sm\:focus-within\:ring-offset-brand:focus-within {
    --tw-ring-offset-color: #2e5bff;
  }

  .sm\:focus\:ring-offset-trans:focus {
    --tw-ring-offset-color: transparent;
  }

  .sm\:focus\:ring-offset-white:focus {
    --tw-ring-offset-color: #ffffff;
  }

  .sm\:focus\:ring-offset-black:focus {
    --tw-ring-offset-color: #000000;
  }

  .sm\:focus\:ring-offset-red:focus {
    --tw-ring-offset-color: #FA5555;
  }

  .sm\:focus\:ring-offset-green:focus {
    --tw-ring-offset-color: #2ECC71;
  }

  .sm\:focus\:ring-offset-yellow:focus {
    --tw-ring-offset-color: #F4A72C;
  }

  .sm\:focus\:ring-offset-brand:focus {
    --tw-ring-offset-color: #2e5bff;
  }

  .sm\:filter {
    --tw-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
    --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
    -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
            filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .sm\:filter-none {
    -webkit-filter: none;
            filter: none;
  }

  .sm\:blur-0 {
    --tw-blur: blur(0);
  }

  .sm\:blur-none {
    --tw-blur: blur(0);
  }

  .sm\:blur-sm {
    --tw-blur: blur(4px);
  }

  .sm\:blur {
    --tw-blur: blur(8px);
  }

  .sm\:blur-md {
    --tw-blur: blur(12px);
  }

  .sm\:blur-lg {
    --tw-blur: blur(16px);
  }

  .sm\:blur-xl {
    --tw-blur: blur(24px);
  }

  .sm\:blur-2xl {
    --tw-blur: blur(40px);
  }

  .sm\:blur-3xl {
    --tw-blur: blur(64px);
  }

  .sm\:brightness-0 {
    --tw-brightness: brightness(0);
  }

  .sm\:brightness-50 {
    --tw-brightness: brightness(.5);
  }

  .sm\:brightness-75 {
    --tw-brightness: brightness(.75);
  }

  .sm\:brightness-90 {
    --tw-brightness: brightness(.9);
  }

  .sm\:brightness-95 {
    --tw-brightness: brightness(.95);
  }

  .sm\:brightness-100 {
    --tw-brightness: brightness(1);
  }

  .sm\:brightness-105 {
    --tw-brightness: brightness(1.05);
  }

  .sm\:brightness-110 {
    --tw-brightness: brightness(1.1);
  }

  .sm\:brightness-125 {
    --tw-brightness: brightness(1.25);
  }

  .sm\:brightness-150 {
    --tw-brightness: brightness(1.5);
  }

  .sm\:brightness-200 {
    --tw-brightness: brightness(2);
  }

  .sm\:contrast-0 {
    --tw-contrast: contrast(0);
  }

  .sm\:contrast-50 {
    --tw-contrast: contrast(.5);
  }

  .sm\:contrast-75 {
    --tw-contrast: contrast(.75);
  }

  .sm\:contrast-100 {
    --tw-contrast: contrast(1);
  }

  .sm\:contrast-125 {
    --tw-contrast: contrast(1.25);
  }

  .sm\:contrast-150 {
    --tw-contrast: contrast(1.5);
  }

  .sm\:contrast-200 {
    --tw-contrast: contrast(2);
  }

  .sm\:drop-shadow-sm {
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05));
  }

  .sm\:drop-shadow {
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06));
  }

  .sm\:drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
  }

  .sm\:drop-shadow-lg {
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1));
  }

  .sm\:drop-shadow-xl {
    --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08));
  }

  .sm\:drop-shadow-2xl {
    --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15));
  }

  .sm\:drop-shadow-none {
    --tw-drop-shadow: drop-shadow(0 0 #0000);
  }

  .sm\:grayscale-0 {
    --tw-grayscale: grayscale(0);
  }

  .sm\:grayscale {
    --tw-grayscale: grayscale(100%);
  }

  .sm\:hue-rotate-0 {
    --tw-hue-rotate: hue-rotate(0deg);
  }

  .sm\:hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(15deg);
  }

  .sm\:hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(30deg);
  }

  .sm\:hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(60deg);
  }

  .sm\:hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(90deg);
  }

  .sm\:hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(180deg);
  }

  .sm\:-hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(-180deg);
  }

  .sm\:-hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(-90deg);
  }

  .sm\:-hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(-60deg);
  }

  .sm\:-hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(-30deg);
  }

  .sm\:-hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(-15deg);
  }

  .sm\:invert-0 {
    --tw-invert: invert(0);
  }

  .sm\:invert {
    --tw-invert: invert(100%);
  }

  .sm\:saturate-0 {
    --tw-saturate: saturate(0);
  }

  .sm\:saturate-50 {
    --tw-saturate: saturate(.5);
  }

  .sm\:saturate-100 {
    --tw-saturate: saturate(1);
  }

  .sm\:saturate-150 {
    --tw-saturate: saturate(1.5);
  }

  .sm\:saturate-200 {
    --tw-saturate: saturate(2);
  }

  .sm\:sepia-0 {
    --tw-sepia: sepia(0);
  }

  .sm\:sepia {
    --tw-sepia: sepia(100%);
  }

  .sm\:backdrop-filter {
    --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .sm\:backdrop-filter-none {
    -webkit-backdrop-filter: none;
            backdrop-filter: none;
  }

  .sm\:backdrop-blur-0 {
    --tw-backdrop-blur: blur(0);
  }

  .sm\:backdrop-blur-none {
    --tw-backdrop-blur: blur(0);
  }

  .sm\:backdrop-blur-sm {
    --tw-backdrop-blur: blur(4px);
  }

  .sm\:backdrop-blur {
    --tw-backdrop-blur: blur(8px);
  }

  .sm\:backdrop-blur-md {
    --tw-backdrop-blur: blur(12px);
  }

  .sm\:backdrop-blur-lg {
    --tw-backdrop-blur: blur(16px);
  }

  .sm\:backdrop-blur-xl {
    --tw-backdrop-blur: blur(24px);
  }

  .sm\:backdrop-blur-2xl {
    --tw-backdrop-blur: blur(40px);
  }

  .sm\:backdrop-blur-3xl {
    --tw-backdrop-blur: blur(64px);
  }

  .sm\:backdrop-brightness-0 {
    --tw-backdrop-brightness: brightness(0);
  }

  .sm\:backdrop-brightness-50 {
    --tw-backdrop-brightness: brightness(.5);
  }

  .sm\:backdrop-brightness-75 {
    --tw-backdrop-brightness: brightness(.75);
  }

  .sm\:backdrop-brightness-90 {
    --tw-backdrop-brightness: brightness(.9);
  }

  .sm\:backdrop-brightness-95 {
    --tw-backdrop-brightness: brightness(.95);
  }

  .sm\:backdrop-brightness-100 {
    --tw-backdrop-brightness: brightness(1);
  }

  .sm\:backdrop-brightness-105 {
    --tw-backdrop-brightness: brightness(1.05);
  }

  .sm\:backdrop-brightness-110 {
    --tw-backdrop-brightness: brightness(1.1);
  }

  .sm\:backdrop-brightness-125 {
    --tw-backdrop-brightness: brightness(1.25);
  }

  .sm\:backdrop-brightness-150 {
    --tw-backdrop-brightness: brightness(1.5);
  }

  .sm\:backdrop-brightness-200 {
    --tw-backdrop-brightness: brightness(2);
  }

  .sm\:backdrop-contrast-0 {
    --tw-backdrop-contrast: contrast(0);
  }

  .sm\:backdrop-contrast-50 {
    --tw-backdrop-contrast: contrast(.5);
  }

  .sm\:backdrop-contrast-75 {
    --tw-backdrop-contrast: contrast(.75);
  }

  .sm\:backdrop-contrast-100 {
    --tw-backdrop-contrast: contrast(1);
  }

  .sm\:backdrop-contrast-125 {
    --tw-backdrop-contrast: contrast(1.25);
  }

  .sm\:backdrop-contrast-150 {
    --tw-backdrop-contrast: contrast(1.5);
  }

  .sm\:backdrop-contrast-200 {
    --tw-backdrop-contrast: contrast(2);
  }

  .sm\:backdrop-grayscale-0 {
    --tw-backdrop-grayscale: grayscale(0);
  }

  .sm\:backdrop-grayscale {
    --tw-backdrop-grayscale: grayscale(100%);
  }

  .sm\:backdrop-hue-rotate-0 {
    --tw-backdrop-hue-rotate: hue-rotate(0deg);
  }

  .sm\:backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(15deg);
  }

  .sm\:backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(30deg);
  }

  .sm\:backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(60deg);
  }

  .sm\:backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(90deg);
  }

  .sm\:backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(180deg);
  }

  .sm\:-backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(-180deg);
  }

  .sm\:-backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(-90deg);
  }

  .sm\:-backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(-60deg);
  }

  .sm\:-backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(-30deg);
  }

  .sm\:-backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(-15deg);
  }

  .sm\:backdrop-invert-0 {
    --tw-backdrop-invert: invert(0);
  }

  .sm\:backdrop-invert {
    --tw-backdrop-invert: invert(100%);
  }

  .sm\:backdrop-opacity-0 {
    --tw-backdrop-opacity: opacity(0);
  }

  .sm\:backdrop-opacity-5 {
    --tw-backdrop-opacity: opacity(0.05);
  }

  .sm\:backdrop-opacity-10 {
    --tw-backdrop-opacity: opacity(0.1);
  }

  .sm\:backdrop-opacity-20 {
    --tw-backdrop-opacity: opacity(0.2);
  }

  .sm\:backdrop-opacity-25 {
    --tw-backdrop-opacity: opacity(0.25);
  }

  .sm\:backdrop-opacity-30 {
    --tw-backdrop-opacity: opacity(0.3);
  }

  .sm\:backdrop-opacity-40 {
    --tw-backdrop-opacity: opacity(0.4);
  }

  .sm\:backdrop-opacity-50 {
    --tw-backdrop-opacity: opacity(0.5);
  }

  .sm\:backdrop-opacity-60 {
    --tw-backdrop-opacity: opacity(0.6);
  }

  .sm\:backdrop-opacity-70 {
    --tw-backdrop-opacity: opacity(0.7);
  }

  .sm\:backdrop-opacity-75 {
    --tw-backdrop-opacity: opacity(0.75);
  }

  .sm\:backdrop-opacity-80 {
    --tw-backdrop-opacity: opacity(0.8);
  }

  .sm\:backdrop-opacity-90 {
    --tw-backdrop-opacity: opacity(0.9);
  }

  .sm\:backdrop-opacity-95 {
    --tw-backdrop-opacity: opacity(0.95);
  }

  .sm\:backdrop-opacity-100 {
    --tw-backdrop-opacity: opacity(1);
  }

  .sm\:backdrop-saturate-0 {
    --tw-backdrop-saturate: saturate(0);
  }

  .sm\:backdrop-saturate-50 {
    --tw-backdrop-saturate: saturate(.5);
  }

  .sm\:backdrop-saturate-100 {
    --tw-backdrop-saturate: saturate(1);
  }

  .sm\:backdrop-saturate-150 {
    --tw-backdrop-saturate: saturate(1.5);
  }

  .sm\:backdrop-saturate-200 {
    --tw-backdrop-saturate: saturate(2);
  }

  .sm\:backdrop-sepia-0 {
    --tw-backdrop-sepia: sepia(0);
  }

  .sm\:backdrop-sepia {
    --tw-backdrop-sepia: sepia(100%);
  }
}

@media (min-width: 768px) {
  .md\:isolate {
    isolation: isolate;
  }

  .md\:isolation-auto {
    isolation: auto;
  }

  .md\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .md\:overflow-ellipsis {
    text-overflow: ellipsis;
  }

  .md\:overflow-clip {
    text-overflow: clip;
  }

  .md\:decoration-slice {
    -webkit-box-decoration-break: slice;
            box-decoration-break: slice;
  }

  .md\:decoration-clone {
    -webkit-box-decoration-break: clone;
            box-decoration-break: clone;
  }

  .md\:bg-origin-border {
    background-origin: border-box;
  }

  .md\:bg-origin-padding {
    background-origin: padding-box;
  }

  .md\:bg-origin-content {
    background-origin: content-box;
  }

  .md\:bg-blend-normal {
    background-blend-mode: normal;
  }

  .md\:bg-blend-multiply {
    background-blend-mode: multiply;
  }

  .md\:bg-blend-screen {
    background-blend-mode: screen;
  }

  .md\:bg-blend-overlay {
    background-blend-mode: overlay;
  }

  .md\:bg-blend-darken {
    background-blend-mode: darken;
  }

  .md\:bg-blend-lighten {
    background-blend-mode: lighten;
  }

  .md\:bg-blend-color-dodge {
    background-blend-mode: color-dodge;
  }

  .md\:bg-blend-color-burn {
    background-blend-mode: color-burn;
  }

  .md\:bg-blend-hard-light {
    background-blend-mode: hard-light;
  }

  .md\:bg-blend-soft-light {
    background-blend-mode: soft-light;
  }

  .md\:bg-blend-difference {
    background-blend-mode: difference;
  }

  .md\:bg-blend-exclusion {
    background-blend-mode: exclusion;
  }

  .md\:bg-blend-hue {
    background-blend-mode: hue;
  }

  .md\:bg-blend-saturation {
    background-blend-mode: saturation;
  }

  .md\:bg-blend-color {
    background-blend-mode: color;
  }

  .md\:bg-blend-luminosity {
    background-blend-mode: luminosity;
  }

  .md\:mix-blend-normal {
    mix-blend-mode: normal;
  }

  .md\:mix-blend-multiply {
    mix-blend-mode: multiply;
  }

  .md\:mix-blend-screen {
    mix-blend-mode: screen;
  }

  .md\:mix-blend-overlay {
    mix-blend-mode: overlay;
  }

  .md\:mix-blend-darken {
    mix-blend-mode: darken;
  }

  .md\:mix-blend-lighten {
    mix-blend-mode: lighten;
  }

  .md\:mix-blend-color-dodge {
    mix-blend-mode: color-dodge;
  }

  .md\:mix-blend-color-burn {
    mix-blend-mode: color-burn;
  }

  .md\:mix-blend-hard-light {
    mix-blend-mode: hard-light;
  }

  .md\:mix-blend-soft-light {
    mix-blend-mode: soft-light;
  }

  .md\:mix-blend-difference {
    mix-blend-mode: difference;
  }

  .md\:mix-blend-exclusion {
    mix-blend-mode: exclusion;
  }

  .md\:mix-blend-hue {
    mix-blend-mode: hue;
  }

  .md\:mix-blend-saturation {
    mix-blend-mode: saturation;
  }

  .md\:mix-blend-color {
    mix-blend-mode: color;
  }

  .md\:mix-blend-luminosity {
    mix-blend-mode: luminosity;
  }

  .md\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus-within\:ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus-within\:ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus-within\:ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus-within\:ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus-within\:ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus-within\:ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus\:ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus\:ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .md\:ring-inset {
    --tw-ring-inset: inset;
  }

  .md\:focus-within\:ring-inset:focus-within {
    --tw-ring-inset: inset;
  }

  .md\:focus\:ring-inset:focus {
    --tw-ring-inset: inset;
  }

  .md\:ring-trans {
    --tw-ring-color: transparent;
  }

  .md\:ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .md\:ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .md\:ring-red {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 85, 85, var(--tw-ring-opacity));
  }

  .md\:ring-green {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 204, 113, var(--tw-ring-opacity));
  }

  .md\:ring-yellow {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 167, 44, var(--tw-ring-opacity));
  }

  .md\:ring-brand {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 91, 255, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-trans:focus-within {
    --tw-ring-color: transparent;
  }

  .md\:focus-within\:ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-red:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 85, 85, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-green:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 204, 113, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-yellow:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 167, 44, var(--tw-ring-opacity));
  }

  .md\:focus-within\:ring-brand:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 91, 255, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-trans:focus {
    --tw-ring-color: transparent;
  }

  .md\:focus\:ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-red:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 85, 85, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-green:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 204, 113, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-yellow:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 167, 44, var(--tw-ring-opacity));
  }

  .md\:focus\:ring-brand:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 91, 255, var(--tw-ring-opacity));
  }

  .md\:ring-opacity-0 {
    --tw-ring-opacity: 0;
  }

  .md\:ring-opacity-5 {
    --tw-ring-opacity: 0.05;
  }

  .md\:ring-opacity-10 {
    --tw-ring-opacity: 0.1;
  }

  .md\:ring-opacity-20 {
    --tw-ring-opacity: 0.2;
  }

  .md\:ring-opacity-25 {
    --tw-ring-opacity: 0.25;
  }

  .md\:ring-opacity-30 {
    --tw-ring-opacity: 0.3;
  }

  .md\:ring-opacity-40 {
    --tw-ring-opacity: 0.4;
  }

  .md\:ring-opacity-50 {
    --tw-ring-opacity: 0.5;
  }

  .md\:ring-opacity-60 {
    --tw-ring-opacity: 0.6;
  }

  .md\:ring-opacity-70 {
    --tw-ring-opacity: 0.7;
  }

  .md\:ring-opacity-75 {
    --tw-ring-opacity: 0.75;
  }

  .md\:ring-opacity-80 {
    --tw-ring-opacity: 0.8;
  }

  .md\:ring-opacity-90 {
    --tw-ring-opacity: 0.9;
  }

  .md\:ring-opacity-95 {
    --tw-ring-opacity: 0.95;
  }

  .md\:ring-opacity-100 {
    --tw-ring-opacity: 1;
  }

  .md\:focus-within\:ring-opacity-0:focus-within {
    --tw-ring-opacity: 0;
  }

  .md\:focus-within\:ring-opacity-5:focus-within {
    --tw-ring-opacity: 0.05;
  }

  .md\:focus-within\:ring-opacity-10:focus-within {
    --tw-ring-opacity: 0.1;
  }

  .md\:focus-within\:ring-opacity-20:focus-within {
    --tw-ring-opacity: 0.2;
  }

  .md\:focus-within\:ring-opacity-25:focus-within {
    --tw-ring-opacity: 0.25;
  }

  .md\:focus-within\:ring-opacity-30:focus-within {
    --tw-ring-opacity: 0.3;
  }

  .md\:focus-within\:ring-opacity-40:focus-within {
    --tw-ring-opacity: 0.4;
  }

  .md\:focus-within\:ring-opacity-50:focus-within {
    --tw-ring-opacity: 0.5;
  }

  .md\:focus-within\:ring-opacity-60:focus-within {
    --tw-ring-opacity: 0.6;
  }

  .md\:focus-within\:ring-opacity-70:focus-within {
    --tw-ring-opacity: 0.7;
  }

  .md\:focus-within\:ring-opacity-75:focus-within {
    --tw-ring-opacity: 0.75;
  }

  .md\:focus-within\:ring-opacity-80:focus-within {
    --tw-ring-opacity: 0.8;
  }

  .md\:focus-within\:ring-opacity-90:focus-within {
    --tw-ring-opacity: 0.9;
  }

  .md\:focus-within\:ring-opacity-95:focus-within {
    --tw-ring-opacity: 0.95;
  }

  .md\:focus-within\:ring-opacity-100:focus-within {
    --tw-ring-opacity: 1;
  }

  .md\:focus\:ring-opacity-0:focus {
    --tw-ring-opacity: 0;
  }

  .md\:focus\:ring-opacity-5:focus {
    --tw-ring-opacity: 0.05;
  }

  .md\:focus\:ring-opacity-10:focus {
    --tw-ring-opacity: 0.1;
  }

  .md\:focus\:ring-opacity-20:focus {
    --tw-ring-opacity: 0.2;
  }

  .md\:focus\:ring-opacity-25:focus {
    --tw-ring-opacity: 0.25;
  }

  .md\:focus\:ring-opacity-30:focus {
    --tw-ring-opacity: 0.3;
  }

  .md\:focus\:ring-opacity-40:focus {
    --tw-ring-opacity: 0.4;
  }

  .md\:focus\:ring-opacity-50:focus {
    --tw-ring-opacity: 0.5;
  }

  .md\:focus\:ring-opacity-60:focus {
    --tw-ring-opacity: 0.6;
  }

  .md\:focus\:ring-opacity-70:focus {
    --tw-ring-opacity: 0.7;
  }

  .md\:focus\:ring-opacity-75:focus {
    --tw-ring-opacity: 0.75;
  }

  .md\:focus\:ring-opacity-80:focus {
    --tw-ring-opacity: 0.8;
  }

  .md\:focus\:ring-opacity-90:focus {
    --tw-ring-opacity: 0.9;
  }

  .md\:focus\:ring-opacity-95:focus {
    --tw-ring-opacity: 0.95;
  }

  .md\:focus\:ring-opacity-100:focus {
    --tw-ring-opacity: 1;
  }

  .md\:ring-offset-0 {
    --tw-ring-offset-width: 0px;
  }

  .md\:ring-offset-1 {
    --tw-ring-offset-width: 1px;
  }

  .md\:ring-offset-2 {
    --tw-ring-offset-width: 2px;
  }

  .md\:ring-offset-4 {
    --tw-ring-offset-width: 4px;
  }

  .md\:ring-offset-8 {
    --tw-ring-offset-width: 8px;
  }

  .md\:focus-within\:ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px;
  }

  .md\:focus-within\:ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px;
  }

  .md\:focus-within\:ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px;
  }

  .md\:focus-within\:ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px;
  }

  .md\:focus-within\:ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px;
  }

  .md\:focus\:ring-offset-0:focus {
    --tw-ring-offset-width: 0px;
  }

  .md\:focus\:ring-offset-1:focus {
    --tw-ring-offset-width: 1px;
  }

  .md\:focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
  }

  .md\:focus\:ring-offset-4:focus {
    --tw-ring-offset-width: 4px;
  }

  .md\:focus\:ring-offset-8:focus {
    --tw-ring-offset-width: 8px;
  }

  .md\:ring-offset-trans {
    --tw-ring-offset-color: transparent;
  }

  .md\:ring-offset-white {
    --tw-ring-offset-color: #ffffff;
  }

  .md\:ring-offset-black {
    --tw-ring-offset-color: #000000;
  }

  .md\:ring-offset-red {
    --tw-ring-offset-color: #FA5555;
  }

  .md\:ring-offset-green {
    --tw-ring-offset-color: #2ECC71;
  }

  .md\:ring-offset-yellow {
    --tw-ring-offset-color: #F4A72C;
  }

  .md\:ring-offset-brand {
    --tw-ring-offset-color: #2e5bff;
  }

  .md\:focus-within\:ring-offset-trans:focus-within {
    --tw-ring-offset-color: transparent;
  }

  .md\:focus-within\:ring-offset-white:focus-within {
    --tw-ring-offset-color: #ffffff;
  }

  .md\:focus-within\:ring-offset-black:focus-within {
    --tw-ring-offset-color: #000000;
  }

  .md\:focus-within\:ring-offset-red:focus-within {
    --tw-ring-offset-color: #FA5555;
  }

  .md\:focus-within\:ring-offset-green:focus-within {
    --tw-ring-offset-color: #2ECC71;
  }

  .md\:focus-within\:ring-offset-yellow:focus-within {
    --tw-ring-offset-color: #F4A72C;
  }

  .md\:focus-within\:ring-offset-brand:focus-within {
    --tw-ring-offset-color: #2e5bff;
  }

  .md\:focus\:ring-offset-trans:focus {
    --tw-ring-offset-color: transparent;
  }

  .md\:focus\:ring-offset-white:focus {
    --tw-ring-offset-color: #ffffff;
  }

  .md\:focus\:ring-offset-black:focus {
    --tw-ring-offset-color: #000000;
  }

  .md\:focus\:ring-offset-red:focus {
    --tw-ring-offset-color: #FA5555;
  }

  .md\:focus\:ring-offset-green:focus {
    --tw-ring-offset-color: #2ECC71;
  }

  .md\:focus\:ring-offset-yellow:focus {
    --tw-ring-offset-color: #F4A72C;
  }

  .md\:focus\:ring-offset-brand:focus {
    --tw-ring-offset-color: #2e5bff;
  }

  .md\:filter {
    --tw-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
    --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
    -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
            filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:filter-none {
    -webkit-filter: none;
            filter: none;
  }

  .md\:blur-0 {
    --tw-blur: blur(0);
  }

  .md\:blur-none {
    --tw-blur: blur(0);
  }

  .md\:blur-sm {
    --tw-blur: blur(4px);
  }

  .md\:blur {
    --tw-blur: blur(8px);
  }

  .md\:blur-md {
    --tw-blur: blur(12px);
  }

  .md\:blur-lg {
    --tw-blur: blur(16px);
  }

  .md\:blur-xl {
    --tw-blur: blur(24px);
  }

  .md\:blur-2xl {
    --tw-blur: blur(40px);
  }

  .md\:blur-3xl {
    --tw-blur: blur(64px);
  }

  .md\:brightness-0 {
    --tw-brightness: brightness(0);
  }

  .md\:brightness-50 {
    --tw-brightness: brightness(.5);
  }

  .md\:brightness-75 {
    --tw-brightness: brightness(.75);
  }

  .md\:brightness-90 {
    --tw-brightness: brightness(.9);
  }

  .md\:brightness-95 {
    --tw-brightness: brightness(.95);
  }

  .md\:brightness-100 {
    --tw-brightness: brightness(1);
  }

  .md\:brightness-105 {
    --tw-brightness: brightness(1.05);
  }

  .md\:brightness-110 {
    --tw-brightness: brightness(1.1);
  }

  .md\:brightness-125 {
    --tw-brightness: brightness(1.25);
  }

  .md\:brightness-150 {
    --tw-brightness: brightness(1.5);
  }

  .md\:brightness-200 {
    --tw-brightness: brightness(2);
  }

  .md\:contrast-0 {
    --tw-contrast: contrast(0);
  }

  .md\:contrast-50 {
    --tw-contrast: contrast(.5);
  }

  .md\:contrast-75 {
    --tw-contrast: contrast(.75);
  }

  .md\:contrast-100 {
    --tw-contrast: contrast(1);
  }

  .md\:contrast-125 {
    --tw-contrast: contrast(1.25);
  }

  .md\:contrast-150 {
    --tw-contrast: contrast(1.5);
  }

  .md\:contrast-200 {
    --tw-contrast: contrast(2);
  }

  .md\:drop-shadow-sm {
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05));
  }

  .md\:drop-shadow {
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06));
  }

  .md\:drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
  }

  .md\:drop-shadow-lg {
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1));
  }

  .md\:drop-shadow-xl {
    --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08));
  }

  .md\:drop-shadow-2xl {
    --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15));
  }

  .md\:drop-shadow-none {
    --tw-drop-shadow: drop-shadow(0 0 #0000);
  }

  .md\:grayscale-0 {
    --tw-grayscale: grayscale(0);
  }

  .md\:grayscale {
    --tw-grayscale: grayscale(100%);
  }

  .md\:hue-rotate-0 {
    --tw-hue-rotate: hue-rotate(0deg);
  }

  .md\:hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(15deg);
  }

  .md\:hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(30deg);
  }

  .md\:hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(60deg);
  }

  .md\:hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(90deg);
  }

  .md\:hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(180deg);
  }

  .md\:-hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(-180deg);
  }

  .md\:-hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(-90deg);
  }

  .md\:-hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(-60deg);
  }

  .md\:-hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(-30deg);
  }

  .md\:-hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(-15deg);
  }

  .md\:invert-0 {
    --tw-invert: invert(0);
  }

  .md\:invert {
    --tw-invert: invert(100%);
  }

  .md\:saturate-0 {
    --tw-saturate: saturate(0);
  }

  .md\:saturate-50 {
    --tw-saturate: saturate(.5);
  }

  .md\:saturate-100 {
    --tw-saturate: saturate(1);
  }

  .md\:saturate-150 {
    --tw-saturate: saturate(1.5);
  }

  .md\:saturate-200 {
    --tw-saturate: saturate(2);
  }

  .md\:sepia-0 {
    --tw-sepia: sepia(0);
  }

  .md\:sepia {
    --tw-sepia: sepia(100%);
  }

  .md\:backdrop-filter {
    --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .md\:backdrop-filter-none {
    -webkit-backdrop-filter: none;
            backdrop-filter: none;
  }

  .md\:backdrop-blur-0 {
    --tw-backdrop-blur: blur(0);
  }

  .md\:backdrop-blur-none {
    --tw-backdrop-blur: blur(0);
  }

  .md\:backdrop-blur-sm {
    --tw-backdrop-blur: blur(4px);
  }

  .md\:backdrop-blur {
    --tw-backdrop-blur: blur(8px);
  }

  .md\:backdrop-blur-md {
    --tw-backdrop-blur: blur(12px);
  }

  .md\:backdrop-blur-lg {
    --tw-backdrop-blur: blur(16px);
  }

  .md\:backdrop-blur-xl {
    --tw-backdrop-blur: blur(24px);
  }

  .md\:backdrop-blur-2xl {
    --tw-backdrop-blur: blur(40px);
  }

  .md\:backdrop-blur-3xl {
    --tw-backdrop-blur: blur(64px);
  }

  .md\:backdrop-brightness-0 {
    --tw-backdrop-brightness: brightness(0);
  }

  .md\:backdrop-brightness-50 {
    --tw-backdrop-brightness: brightness(.5);
  }

  .md\:backdrop-brightness-75 {
    --tw-backdrop-brightness: brightness(.75);
  }

  .md\:backdrop-brightness-90 {
    --tw-backdrop-brightness: brightness(.9);
  }

  .md\:backdrop-brightness-95 {
    --tw-backdrop-brightness: brightness(.95);
  }

  .md\:backdrop-brightness-100 {
    --tw-backdrop-brightness: brightness(1);
  }

  .md\:backdrop-brightness-105 {
    --tw-backdrop-brightness: brightness(1.05);
  }

  .md\:backdrop-brightness-110 {
    --tw-backdrop-brightness: brightness(1.1);
  }

  .md\:backdrop-brightness-125 {
    --tw-backdrop-brightness: brightness(1.25);
  }

  .md\:backdrop-brightness-150 {
    --tw-backdrop-brightness: brightness(1.5);
  }

  .md\:backdrop-brightness-200 {
    --tw-backdrop-brightness: brightness(2);
  }

  .md\:backdrop-contrast-0 {
    --tw-backdrop-contrast: contrast(0);
  }

  .md\:backdrop-contrast-50 {
    --tw-backdrop-contrast: contrast(.5);
  }

  .md\:backdrop-contrast-75 {
    --tw-backdrop-contrast: contrast(.75);
  }

  .md\:backdrop-contrast-100 {
    --tw-backdrop-contrast: contrast(1);
  }

  .md\:backdrop-contrast-125 {
    --tw-backdrop-contrast: contrast(1.25);
  }

  .md\:backdrop-contrast-150 {
    --tw-backdrop-contrast: contrast(1.5);
  }

  .md\:backdrop-contrast-200 {
    --tw-backdrop-contrast: contrast(2);
  }

  .md\:backdrop-grayscale-0 {
    --tw-backdrop-grayscale: grayscale(0);
  }

  .md\:backdrop-grayscale {
    --tw-backdrop-grayscale: grayscale(100%);
  }

  .md\:backdrop-hue-rotate-0 {
    --tw-backdrop-hue-rotate: hue-rotate(0deg);
  }

  .md\:backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(15deg);
  }

  .md\:backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(30deg);
  }

  .md\:backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(60deg);
  }

  .md\:backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(90deg);
  }

  .md\:backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(180deg);
  }

  .md\:-backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(-180deg);
  }

  .md\:-backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(-90deg);
  }

  .md\:-backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(-60deg);
  }

  .md\:-backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(-30deg);
  }

  .md\:-backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(-15deg);
  }

  .md\:backdrop-invert-0 {
    --tw-backdrop-invert: invert(0);
  }

  .md\:backdrop-invert {
    --tw-backdrop-invert: invert(100%);
  }

  .md\:backdrop-opacity-0 {
    --tw-backdrop-opacity: opacity(0);
  }

  .md\:backdrop-opacity-5 {
    --tw-backdrop-opacity: opacity(0.05);
  }

  .md\:backdrop-opacity-10 {
    --tw-backdrop-opacity: opacity(0.1);
  }

  .md\:backdrop-opacity-20 {
    --tw-backdrop-opacity: opacity(0.2);
  }

  .md\:backdrop-opacity-25 {
    --tw-backdrop-opacity: opacity(0.25);
  }

  .md\:backdrop-opacity-30 {
    --tw-backdrop-opacity: opacity(0.3);
  }

  .md\:backdrop-opacity-40 {
    --tw-backdrop-opacity: opacity(0.4);
  }

  .md\:backdrop-opacity-50 {
    --tw-backdrop-opacity: opacity(0.5);
  }

  .md\:backdrop-opacity-60 {
    --tw-backdrop-opacity: opacity(0.6);
  }

  .md\:backdrop-opacity-70 {
    --tw-backdrop-opacity: opacity(0.7);
  }

  .md\:backdrop-opacity-75 {
    --tw-backdrop-opacity: opacity(0.75);
  }

  .md\:backdrop-opacity-80 {
    --tw-backdrop-opacity: opacity(0.8);
  }

  .md\:backdrop-opacity-90 {
    --tw-backdrop-opacity: opacity(0.9);
  }

  .md\:backdrop-opacity-95 {
    --tw-backdrop-opacity: opacity(0.95);
  }

  .md\:backdrop-opacity-100 {
    --tw-backdrop-opacity: opacity(1);
  }

  .md\:backdrop-saturate-0 {
    --tw-backdrop-saturate: saturate(0);
  }

  .md\:backdrop-saturate-50 {
    --tw-backdrop-saturate: saturate(.5);
  }

  .md\:backdrop-saturate-100 {
    --tw-backdrop-saturate: saturate(1);
  }

  .md\:backdrop-saturate-150 {
    --tw-backdrop-saturate: saturate(1.5);
  }

  .md\:backdrop-saturate-200 {
    --tw-backdrop-saturate: saturate(2);
  }

  .md\:backdrop-sepia-0 {
    --tw-backdrop-sepia: sepia(0);
  }

  .md\:backdrop-sepia {
    --tw-backdrop-sepia: sepia(100%);
  }
}

@media (min-width: 1024px) {
  .lg\:isolate {
    isolation: isolate;
  }

  .lg\:isolation-auto {
    isolation: auto;
  }

  .lg\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .lg\:overflow-ellipsis {
    text-overflow: ellipsis;
  }

  .lg\:overflow-clip {
    text-overflow: clip;
  }

  .lg\:decoration-slice {
    -webkit-box-decoration-break: slice;
            box-decoration-break: slice;
  }

  .lg\:decoration-clone {
    -webkit-box-decoration-break: clone;
            box-decoration-break: clone;
  }

  .lg\:bg-origin-border {
    background-origin: border-box;
  }

  .lg\:bg-origin-padding {
    background-origin: padding-box;
  }

  .lg\:bg-origin-content {
    background-origin: content-box;
  }

  .lg\:bg-blend-normal {
    background-blend-mode: normal;
  }

  .lg\:bg-blend-multiply {
    background-blend-mode: multiply;
  }

  .lg\:bg-blend-screen {
    background-blend-mode: screen;
  }

  .lg\:bg-blend-overlay {
    background-blend-mode: overlay;
  }

  .lg\:bg-blend-darken {
    background-blend-mode: darken;
  }

  .lg\:bg-blend-lighten {
    background-blend-mode: lighten;
  }

  .lg\:bg-blend-color-dodge {
    background-blend-mode: color-dodge;
  }

  .lg\:bg-blend-color-burn {
    background-blend-mode: color-burn;
  }

  .lg\:bg-blend-hard-light {
    background-blend-mode: hard-light;
  }

  .lg\:bg-blend-soft-light {
    background-blend-mode: soft-light;
  }

  .lg\:bg-blend-difference {
    background-blend-mode: difference;
  }

  .lg\:bg-blend-exclusion {
    background-blend-mode: exclusion;
  }

  .lg\:bg-blend-hue {
    background-blend-mode: hue;
  }

  .lg\:bg-blend-saturation {
    background-blend-mode: saturation;
  }

  .lg\:bg-blend-color {
    background-blend-mode: color;
  }

  .lg\:bg-blend-luminosity {
    background-blend-mode: luminosity;
  }

  .lg\:mix-blend-normal {
    mix-blend-mode: normal;
  }

  .lg\:mix-blend-multiply {
    mix-blend-mode: multiply;
  }

  .lg\:mix-blend-screen {
    mix-blend-mode: screen;
  }

  .lg\:mix-blend-overlay {
    mix-blend-mode: overlay;
  }

  .lg\:mix-blend-darken {
    mix-blend-mode: darken;
  }

  .lg\:mix-blend-lighten {
    mix-blend-mode: lighten;
  }

  .lg\:mix-blend-color-dodge {
    mix-blend-mode: color-dodge;
  }

  .lg\:mix-blend-color-burn {
    mix-blend-mode: color-burn;
  }

  .lg\:mix-blend-hard-light {
    mix-blend-mode: hard-light;
  }

  .lg\:mix-blend-soft-light {
    mix-blend-mode: soft-light;
  }

  .lg\:mix-blend-difference {
    mix-blend-mode: difference;
  }

  .lg\:mix-blend-exclusion {
    mix-blend-mode: exclusion;
  }

  .lg\:mix-blend-hue {
    mix-blend-mode: hue;
  }

  .lg\:mix-blend-saturation {
    mix-blend-mode: saturation;
  }

  .lg\:mix-blend-color {
    mix-blend-mode: color;
  }

  .lg\:mix-blend-luminosity {
    mix-blend-mode: luminosity;
  }

  .lg\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus-within\:ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus-within\:ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus-within\:ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus-within\:ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus-within\:ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus-within\:ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus\:ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus\:ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .lg\:ring-inset {
    --tw-ring-inset: inset;
  }

  .lg\:focus-within\:ring-inset:focus-within {
    --tw-ring-inset: inset;
  }

  .lg\:focus\:ring-inset:focus {
    --tw-ring-inset: inset;
  }

  .lg\:ring-trans {
    --tw-ring-color: transparent;
  }

  .lg\:ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .lg\:ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .lg\:ring-red {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 85, 85, var(--tw-ring-opacity));
  }

  .lg\:ring-green {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 204, 113, var(--tw-ring-opacity));
  }

  .lg\:ring-yellow {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 167, 44, var(--tw-ring-opacity));
  }

  .lg\:ring-brand {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 91, 255, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-trans:focus-within {
    --tw-ring-color: transparent;
  }

  .lg\:focus-within\:ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-red:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 85, 85, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-green:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 204, 113, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-yellow:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 167, 44, var(--tw-ring-opacity));
  }

  .lg\:focus-within\:ring-brand:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 91, 255, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-trans:focus {
    --tw-ring-color: transparent;
  }

  .lg\:focus\:ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-red:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 85, 85, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-green:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 204, 113, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-yellow:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 167, 44, var(--tw-ring-opacity));
  }

  .lg\:focus\:ring-brand:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 91, 255, var(--tw-ring-opacity));
  }

  .lg\:ring-opacity-0 {
    --tw-ring-opacity: 0;
  }

  .lg\:ring-opacity-5 {
    --tw-ring-opacity: 0.05;
  }

  .lg\:ring-opacity-10 {
    --tw-ring-opacity: 0.1;
  }

  .lg\:ring-opacity-20 {
    --tw-ring-opacity: 0.2;
  }

  .lg\:ring-opacity-25 {
    --tw-ring-opacity: 0.25;
  }

  .lg\:ring-opacity-30 {
    --tw-ring-opacity: 0.3;
  }

  .lg\:ring-opacity-40 {
    --tw-ring-opacity: 0.4;
  }

  .lg\:ring-opacity-50 {
    --tw-ring-opacity: 0.5;
  }

  .lg\:ring-opacity-60 {
    --tw-ring-opacity: 0.6;
  }

  .lg\:ring-opacity-70 {
    --tw-ring-opacity: 0.7;
  }

  .lg\:ring-opacity-75 {
    --tw-ring-opacity: 0.75;
  }

  .lg\:ring-opacity-80 {
    --tw-ring-opacity: 0.8;
  }

  .lg\:ring-opacity-90 {
    --tw-ring-opacity: 0.9;
  }

  .lg\:ring-opacity-95 {
    --tw-ring-opacity: 0.95;
  }

  .lg\:ring-opacity-100 {
    --tw-ring-opacity: 1;
  }

  .lg\:focus-within\:ring-opacity-0:focus-within {
    --tw-ring-opacity: 0;
  }

  .lg\:focus-within\:ring-opacity-5:focus-within {
    --tw-ring-opacity: 0.05;
  }

  .lg\:focus-within\:ring-opacity-10:focus-within {
    --tw-ring-opacity: 0.1;
  }

  .lg\:focus-within\:ring-opacity-20:focus-within {
    --tw-ring-opacity: 0.2;
  }

  .lg\:focus-within\:ring-opacity-25:focus-within {
    --tw-ring-opacity: 0.25;
  }

  .lg\:focus-within\:ring-opacity-30:focus-within {
    --tw-ring-opacity: 0.3;
  }

  .lg\:focus-within\:ring-opacity-40:focus-within {
    --tw-ring-opacity: 0.4;
  }

  .lg\:focus-within\:ring-opacity-50:focus-within {
    --tw-ring-opacity: 0.5;
  }

  .lg\:focus-within\:ring-opacity-60:focus-within {
    --tw-ring-opacity: 0.6;
  }

  .lg\:focus-within\:ring-opacity-70:focus-within {
    --tw-ring-opacity: 0.7;
  }

  .lg\:focus-within\:ring-opacity-75:focus-within {
    --tw-ring-opacity: 0.75;
  }

  .lg\:focus-within\:ring-opacity-80:focus-within {
    --tw-ring-opacity: 0.8;
  }

  .lg\:focus-within\:ring-opacity-90:focus-within {
    --tw-ring-opacity: 0.9;
  }

  .lg\:focus-within\:ring-opacity-95:focus-within {
    --tw-ring-opacity: 0.95;
  }

  .lg\:focus-within\:ring-opacity-100:focus-within {
    --tw-ring-opacity: 1;
  }

  .lg\:focus\:ring-opacity-0:focus {
    --tw-ring-opacity: 0;
  }

  .lg\:focus\:ring-opacity-5:focus {
    --tw-ring-opacity: 0.05;
  }

  .lg\:focus\:ring-opacity-10:focus {
    --tw-ring-opacity: 0.1;
  }

  .lg\:focus\:ring-opacity-20:focus {
    --tw-ring-opacity: 0.2;
  }

  .lg\:focus\:ring-opacity-25:focus {
    --tw-ring-opacity: 0.25;
  }

  .lg\:focus\:ring-opacity-30:focus {
    --tw-ring-opacity: 0.3;
  }

  .lg\:focus\:ring-opacity-40:focus {
    --tw-ring-opacity: 0.4;
  }

  .lg\:focus\:ring-opacity-50:focus {
    --tw-ring-opacity: 0.5;
  }

  .lg\:focus\:ring-opacity-60:focus {
    --tw-ring-opacity: 0.6;
  }

  .lg\:focus\:ring-opacity-70:focus {
    --tw-ring-opacity: 0.7;
  }

  .lg\:focus\:ring-opacity-75:focus {
    --tw-ring-opacity: 0.75;
  }

  .lg\:focus\:ring-opacity-80:focus {
    --tw-ring-opacity: 0.8;
  }

  .lg\:focus\:ring-opacity-90:focus {
    --tw-ring-opacity: 0.9;
  }

  .lg\:focus\:ring-opacity-95:focus {
    --tw-ring-opacity: 0.95;
  }

  .lg\:focus\:ring-opacity-100:focus {
    --tw-ring-opacity: 1;
  }

  .lg\:ring-offset-0 {
    --tw-ring-offset-width: 0px;
  }

  .lg\:ring-offset-1 {
    --tw-ring-offset-width: 1px;
  }

  .lg\:ring-offset-2 {
    --tw-ring-offset-width: 2px;
  }

  .lg\:ring-offset-4 {
    --tw-ring-offset-width: 4px;
  }

  .lg\:ring-offset-8 {
    --tw-ring-offset-width: 8px;
  }

  .lg\:focus-within\:ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px;
  }

  .lg\:focus-within\:ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px;
  }

  .lg\:focus-within\:ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px;
  }

  .lg\:focus-within\:ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px;
  }

  .lg\:focus-within\:ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px;
  }

  .lg\:focus\:ring-offset-0:focus {
    --tw-ring-offset-width: 0px;
  }

  .lg\:focus\:ring-offset-1:focus {
    --tw-ring-offset-width: 1px;
  }

  .lg\:focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
  }

  .lg\:focus\:ring-offset-4:focus {
    --tw-ring-offset-width: 4px;
  }

  .lg\:focus\:ring-offset-8:focus {
    --tw-ring-offset-width: 8px;
  }

  .lg\:ring-offset-trans {
    --tw-ring-offset-color: transparent;
  }

  .lg\:ring-offset-white {
    --tw-ring-offset-color: #ffffff;
  }

  .lg\:ring-offset-black {
    --tw-ring-offset-color: #000000;
  }

  .lg\:ring-offset-red {
    --tw-ring-offset-color: #FA5555;
  }

  .lg\:ring-offset-green {
    --tw-ring-offset-color: #2ECC71;
  }

  .lg\:ring-offset-yellow {
    --tw-ring-offset-color: #F4A72C;
  }

  .lg\:ring-offset-brand {
    --tw-ring-offset-color: #2e5bff;
  }

  .lg\:focus-within\:ring-offset-trans:focus-within {
    --tw-ring-offset-color: transparent;
  }

  .lg\:focus-within\:ring-offset-white:focus-within {
    --tw-ring-offset-color: #ffffff;
  }

  .lg\:focus-within\:ring-offset-black:focus-within {
    --tw-ring-offset-color: #000000;
  }

  .lg\:focus-within\:ring-offset-red:focus-within {
    --tw-ring-offset-color: #FA5555;
  }

  .lg\:focus-within\:ring-offset-green:focus-within {
    --tw-ring-offset-color: #2ECC71;
  }

  .lg\:focus-within\:ring-offset-yellow:focus-within {
    --tw-ring-offset-color: #F4A72C;
  }

  .lg\:focus-within\:ring-offset-brand:focus-within {
    --tw-ring-offset-color: #2e5bff;
  }

  .lg\:focus\:ring-offset-trans:focus {
    --tw-ring-offset-color: transparent;
  }

  .lg\:focus\:ring-offset-white:focus {
    --tw-ring-offset-color: #ffffff;
  }

  .lg\:focus\:ring-offset-black:focus {
    --tw-ring-offset-color: #000000;
  }

  .lg\:focus\:ring-offset-red:focus {
    --tw-ring-offset-color: #FA5555;
  }

  .lg\:focus\:ring-offset-green:focus {
    --tw-ring-offset-color: #2ECC71;
  }

  .lg\:focus\:ring-offset-yellow:focus {
    --tw-ring-offset-color: #F4A72C;
  }

  .lg\:focus\:ring-offset-brand:focus {
    --tw-ring-offset-color: #2e5bff;
  }

  .lg\:filter {
    --tw-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
    --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
    -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
            filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .lg\:filter-none {
    -webkit-filter: none;
            filter: none;
  }

  .lg\:blur-0 {
    --tw-blur: blur(0);
  }

  .lg\:blur-none {
    --tw-blur: blur(0);
  }

  .lg\:blur-sm {
    --tw-blur: blur(4px);
  }

  .lg\:blur {
    --tw-blur: blur(8px);
  }

  .lg\:blur-md {
    --tw-blur: blur(12px);
  }

  .lg\:blur-lg {
    --tw-blur: blur(16px);
  }

  .lg\:blur-xl {
    --tw-blur: blur(24px);
  }

  .lg\:blur-2xl {
    --tw-blur: blur(40px);
  }

  .lg\:blur-3xl {
    --tw-blur: blur(64px);
  }

  .lg\:brightness-0 {
    --tw-brightness: brightness(0);
  }

  .lg\:brightness-50 {
    --tw-brightness: brightness(.5);
  }

  .lg\:brightness-75 {
    --tw-brightness: brightness(.75);
  }

  .lg\:brightness-90 {
    --tw-brightness: brightness(.9);
  }

  .lg\:brightness-95 {
    --tw-brightness: brightness(.95);
  }

  .lg\:brightness-100 {
    --tw-brightness: brightness(1);
  }

  .lg\:brightness-105 {
    --tw-brightness: brightness(1.05);
  }

  .lg\:brightness-110 {
    --tw-brightness: brightness(1.1);
  }

  .lg\:brightness-125 {
    --tw-brightness: brightness(1.25);
  }

  .lg\:brightness-150 {
    --tw-brightness: brightness(1.5);
  }

  .lg\:brightness-200 {
    --tw-brightness: brightness(2);
  }

  .lg\:contrast-0 {
    --tw-contrast: contrast(0);
  }

  .lg\:contrast-50 {
    --tw-contrast: contrast(.5);
  }

  .lg\:contrast-75 {
    --tw-contrast: contrast(.75);
  }

  .lg\:contrast-100 {
    --tw-contrast: contrast(1);
  }

  .lg\:contrast-125 {
    --tw-contrast: contrast(1.25);
  }

  .lg\:contrast-150 {
    --tw-contrast: contrast(1.5);
  }

  .lg\:contrast-200 {
    --tw-contrast: contrast(2);
  }

  .lg\:drop-shadow-sm {
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05));
  }

  .lg\:drop-shadow {
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06));
  }

  .lg\:drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
  }

  .lg\:drop-shadow-lg {
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1));
  }

  .lg\:drop-shadow-xl {
    --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08));
  }

  .lg\:drop-shadow-2xl {
    --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15));
  }

  .lg\:drop-shadow-none {
    --tw-drop-shadow: drop-shadow(0 0 #0000);
  }

  .lg\:grayscale-0 {
    --tw-grayscale: grayscale(0);
  }

  .lg\:grayscale {
    --tw-grayscale: grayscale(100%);
  }

  .lg\:hue-rotate-0 {
    --tw-hue-rotate: hue-rotate(0deg);
  }

  .lg\:hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(15deg);
  }

  .lg\:hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(30deg);
  }

  .lg\:hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(60deg);
  }

  .lg\:hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(90deg);
  }

  .lg\:hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(180deg);
  }

  .lg\:-hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(-180deg);
  }

  .lg\:-hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(-90deg);
  }

  .lg\:-hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(-60deg);
  }

  .lg\:-hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(-30deg);
  }

  .lg\:-hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(-15deg);
  }

  .lg\:invert-0 {
    --tw-invert: invert(0);
  }

  .lg\:invert {
    --tw-invert: invert(100%);
  }

  .lg\:saturate-0 {
    --tw-saturate: saturate(0);
  }

  .lg\:saturate-50 {
    --tw-saturate: saturate(.5);
  }

  .lg\:saturate-100 {
    --tw-saturate: saturate(1);
  }

  .lg\:saturate-150 {
    --tw-saturate: saturate(1.5);
  }

  .lg\:saturate-200 {
    --tw-saturate: saturate(2);
  }

  .lg\:sepia-0 {
    --tw-sepia: sepia(0);
  }

  .lg\:sepia {
    --tw-sepia: sepia(100%);
  }

  .lg\:backdrop-filter {
    --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .lg\:backdrop-filter-none {
    -webkit-backdrop-filter: none;
            backdrop-filter: none;
  }

  .lg\:backdrop-blur-0 {
    --tw-backdrop-blur: blur(0);
  }

  .lg\:backdrop-blur-none {
    --tw-backdrop-blur: blur(0);
  }

  .lg\:backdrop-blur-sm {
    --tw-backdrop-blur: blur(4px);
  }

  .lg\:backdrop-blur {
    --tw-backdrop-blur: blur(8px);
  }

  .lg\:backdrop-blur-md {
    --tw-backdrop-blur: blur(12px);
  }

  .lg\:backdrop-blur-lg {
    --tw-backdrop-blur: blur(16px);
  }

  .lg\:backdrop-blur-xl {
    --tw-backdrop-blur: blur(24px);
  }

  .lg\:backdrop-blur-2xl {
    --tw-backdrop-blur: blur(40px);
  }

  .lg\:backdrop-blur-3xl {
    --tw-backdrop-blur: blur(64px);
  }

  .lg\:backdrop-brightness-0 {
    --tw-backdrop-brightness: brightness(0);
  }

  .lg\:backdrop-brightness-50 {
    --tw-backdrop-brightness: brightness(.5);
  }

  .lg\:backdrop-brightness-75 {
    --tw-backdrop-brightness: brightness(.75);
  }

  .lg\:backdrop-brightness-90 {
    --tw-backdrop-brightness: brightness(.9);
  }

  .lg\:backdrop-brightness-95 {
    --tw-backdrop-brightness: brightness(.95);
  }

  .lg\:backdrop-brightness-100 {
    --tw-backdrop-brightness: brightness(1);
  }

  .lg\:backdrop-brightness-105 {
    --tw-backdrop-brightness: brightness(1.05);
  }

  .lg\:backdrop-brightness-110 {
    --tw-backdrop-brightness: brightness(1.1);
  }

  .lg\:backdrop-brightness-125 {
    --tw-backdrop-brightness: brightness(1.25);
  }

  .lg\:backdrop-brightness-150 {
    --tw-backdrop-brightness: brightness(1.5);
  }

  .lg\:backdrop-brightness-200 {
    --tw-backdrop-brightness: brightness(2);
  }

  .lg\:backdrop-contrast-0 {
    --tw-backdrop-contrast: contrast(0);
  }

  .lg\:backdrop-contrast-50 {
    --tw-backdrop-contrast: contrast(.5);
  }

  .lg\:backdrop-contrast-75 {
    --tw-backdrop-contrast: contrast(.75);
  }

  .lg\:backdrop-contrast-100 {
    --tw-backdrop-contrast: contrast(1);
  }

  .lg\:backdrop-contrast-125 {
    --tw-backdrop-contrast: contrast(1.25);
  }

  .lg\:backdrop-contrast-150 {
    --tw-backdrop-contrast: contrast(1.5);
  }

  .lg\:backdrop-contrast-200 {
    --tw-backdrop-contrast: contrast(2);
  }

  .lg\:backdrop-grayscale-0 {
    --tw-backdrop-grayscale: grayscale(0);
  }

  .lg\:backdrop-grayscale {
    --tw-backdrop-grayscale: grayscale(100%);
  }

  .lg\:backdrop-hue-rotate-0 {
    --tw-backdrop-hue-rotate: hue-rotate(0deg);
  }

  .lg\:backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(15deg);
  }

  .lg\:backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(30deg);
  }

  .lg\:backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(60deg);
  }

  .lg\:backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(90deg);
  }

  .lg\:backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(180deg);
  }

  .lg\:-backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(-180deg);
  }

  .lg\:-backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(-90deg);
  }

  .lg\:-backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(-60deg);
  }

  .lg\:-backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(-30deg);
  }

  .lg\:-backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(-15deg);
  }

  .lg\:backdrop-invert-0 {
    --tw-backdrop-invert: invert(0);
  }

  .lg\:backdrop-invert {
    --tw-backdrop-invert: invert(100%);
  }

  .lg\:backdrop-opacity-0 {
    --tw-backdrop-opacity: opacity(0);
  }

  .lg\:backdrop-opacity-5 {
    --tw-backdrop-opacity: opacity(0.05);
  }

  .lg\:backdrop-opacity-10 {
    --tw-backdrop-opacity: opacity(0.1);
  }

  .lg\:backdrop-opacity-20 {
    --tw-backdrop-opacity: opacity(0.2);
  }

  .lg\:backdrop-opacity-25 {
    --tw-backdrop-opacity: opacity(0.25);
  }

  .lg\:backdrop-opacity-30 {
    --tw-backdrop-opacity: opacity(0.3);
  }

  .lg\:backdrop-opacity-40 {
    --tw-backdrop-opacity: opacity(0.4);
  }

  .lg\:backdrop-opacity-50 {
    --tw-backdrop-opacity: opacity(0.5);
  }

  .lg\:backdrop-opacity-60 {
    --tw-backdrop-opacity: opacity(0.6);
  }

  .lg\:backdrop-opacity-70 {
    --tw-backdrop-opacity: opacity(0.7);
  }

  .lg\:backdrop-opacity-75 {
    --tw-backdrop-opacity: opacity(0.75);
  }

  .lg\:backdrop-opacity-80 {
    --tw-backdrop-opacity: opacity(0.8);
  }

  .lg\:backdrop-opacity-90 {
    --tw-backdrop-opacity: opacity(0.9);
  }

  .lg\:backdrop-opacity-95 {
    --tw-backdrop-opacity: opacity(0.95);
  }

  .lg\:backdrop-opacity-100 {
    --tw-backdrop-opacity: opacity(1);
  }

  .lg\:backdrop-saturate-0 {
    --tw-backdrop-saturate: saturate(0);
  }

  .lg\:backdrop-saturate-50 {
    --tw-backdrop-saturate: saturate(.5);
  }

  .lg\:backdrop-saturate-100 {
    --tw-backdrop-saturate: saturate(1);
  }

  .lg\:backdrop-saturate-150 {
    --tw-backdrop-saturate: saturate(1.5);
  }

  .lg\:backdrop-saturate-200 {
    --tw-backdrop-saturate: saturate(2);
  }

  .lg\:backdrop-sepia-0 {
    --tw-backdrop-sepia: sepia(0);
  }

  .lg\:backdrop-sepia {
    --tw-backdrop-sepia: sepia(100%);
  }
}

@media (min-width: 1280px) {
  .xl\:isolate {
    isolation: isolate;
  }

  .xl\:isolation-auto {
    isolation: auto;
  }

  .xl\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .xl\:overflow-ellipsis {
    text-overflow: ellipsis;
  }

  .xl\:overflow-clip {
    text-overflow: clip;
  }

  .xl\:decoration-slice {
    -webkit-box-decoration-break: slice;
            box-decoration-break: slice;
  }

  .xl\:decoration-clone {
    -webkit-box-decoration-break: clone;
            box-decoration-break: clone;
  }

  .xl\:bg-origin-border {
    background-origin: border-box;
  }

  .xl\:bg-origin-padding {
    background-origin: padding-box;
  }

  .xl\:bg-origin-content {
    background-origin: content-box;
  }

  .xl\:bg-blend-normal {
    background-blend-mode: normal;
  }

  .xl\:bg-blend-multiply {
    background-blend-mode: multiply;
  }

  .xl\:bg-blend-screen {
    background-blend-mode: screen;
  }

  .xl\:bg-blend-overlay {
    background-blend-mode: overlay;
  }

  .xl\:bg-blend-darken {
    background-blend-mode: darken;
  }

  .xl\:bg-blend-lighten {
    background-blend-mode: lighten;
  }

  .xl\:bg-blend-color-dodge {
    background-blend-mode: color-dodge;
  }

  .xl\:bg-blend-color-burn {
    background-blend-mode: color-burn;
  }

  .xl\:bg-blend-hard-light {
    background-blend-mode: hard-light;
  }

  .xl\:bg-blend-soft-light {
    background-blend-mode: soft-light;
  }

  .xl\:bg-blend-difference {
    background-blend-mode: difference;
  }

  .xl\:bg-blend-exclusion {
    background-blend-mode: exclusion;
  }

  .xl\:bg-blend-hue {
    background-blend-mode: hue;
  }

  .xl\:bg-blend-saturation {
    background-blend-mode: saturation;
  }

  .xl\:bg-blend-color {
    background-blend-mode: color;
  }

  .xl\:bg-blend-luminosity {
    background-blend-mode: luminosity;
  }

  .xl\:mix-blend-normal {
    mix-blend-mode: normal;
  }

  .xl\:mix-blend-multiply {
    mix-blend-mode: multiply;
  }

  .xl\:mix-blend-screen {
    mix-blend-mode: screen;
  }

  .xl\:mix-blend-overlay {
    mix-blend-mode: overlay;
  }

  .xl\:mix-blend-darken {
    mix-blend-mode: darken;
  }

  .xl\:mix-blend-lighten {
    mix-blend-mode: lighten;
  }

  .xl\:mix-blend-color-dodge {
    mix-blend-mode: color-dodge;
  }

  .xl\:mix-blend-color-burn {
    mix-blend-mode: color-burn;
  }

  .xl\:mix-blend-hard-light {
    mix-blend-mode: hard-light;
  }

  .xl\:mix-blend-soft-light {
    mix-blend-mode: soft-light;
  }

  .xl\:mix-blend-difference {
    mix-blend-mode: difference;
  }

  .xl\:mix-blend-exclusion {
    mix-blend-mode: exclusion;
  }

  .xl\:mix-blend-hue {
    mix-blend-mode: hue;
  }

  .xl\:mix-blend-saturation {
    mix-blend-mode: saturation;
  }

  .xl\:mix-blend-color {
    mix-blend-mode: color;
  }

  .xl\:mix-blend-luminosity {
    mix-blend-mode: luminosity;
  }

  .xl\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus-within\:ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus-within\:ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus-within\:ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus-within\:ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus-within\:ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus-within\:ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus\:ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus\:ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:ring-inset {
    --tw-ring-inset: inset;
  }

  .xl\:focus-within\:ring-inset:focus-within {
    --tw-ring-inset: inset;
  }

  .xl\:focus\:ring-inset:focus {
    --tw-ring-inset: inset;
  }

  .xl\:ring-trans {
    --tw-ring-color: transparent;
  }

  .xl\:ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .xl\:ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .xl\:ring-red {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 85, 85, var(--tw-ring-opacity));
  }

  .xl\:ring-green {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 204, 113, var(--tw-ring-opacity));
  }

  .xl\:ring-yellow {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 167, 44, var(--tw-ring-opacity));
  }

  .xl\:ring-brand {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 91, 255, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-trans:focus-within {
    --tw-ring-color: transparent;
  }

  .xl\:focus-within\:ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-red:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 85, 85, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-green:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 204, 113, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-yellow:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 167, 44, var(--tw-ring-opacity));
  }

  .xl\:focus-within\:ring-brand:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 91, 255, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-trans:focus {
    --tw-ring-color: transparent;
  }

  .xl\:focus\:ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-red:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 85, 85, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-green:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 204, 113, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-yellow:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 167, 44, var(--tw-ring-opacity));
  }

  .xl\:focus\:ring-brand:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 91, 255, var(--tw-ring-opacity));
  }

  .xl\:ring-opacity-0 {
    --tw-ring-opacity: 0;
  }

  .xl\:ring-opacity-5 {
    --tw-ring-opacity: 0.05;
  }

  .xl\:ring-opacity-10 {
    --tw-ring-opacity: 0.1;
  }

  .xl\:ring-opacity-20 {
    --tw-ring-opacity: 0.2;
  }

  .xl\:ring-opacity-25 {
    --tw-ring-opacity: 0.25;
  }

  .xl\:ring-opacity-30 {
    --tw-ring-opacity: 0.3;
  }

  .xl\:ring-opacity-40 {
    --tw-ring-opacity: 0.4;
  }

  .xl\:ring-opacity-50 {
    --tw-ring-opacity: 0.5;
  }

  .xl\:ring-opacity-60 {
    --tw-ring-opacity: 0.6;
  }

  .xl\:ring-opacity-70 {
    --tw-ring-opacity: 0.7;
  }

  .xl\:ring-opacity-75 {
    --tw-ring-opacity: 0.75;
  }

  .xl\:ring-opacity-80 {
    --tw-ring-opacity: 0.8;
  }

  .xl\:ring-opacity-90 {
    --tw-ring-opacity: 0.9;
  }

  .xl\:ring-opacity-95 {
    --tw-ring-opacity: 0.95;
  }

  .xl\:ring-opacity-100 {
    --tw-ring-opacity: 1;
  }

  .xl\:focus-within\:ring-opacity-0:focus-within {
    --tw-ring-opacity: 0;
  }

  .xl\:focus-within\:ring-opacity-5:focus-within {
    --tw-ring-opacity: 0.05;
  }

  .xl\:focus-within\:ring-opacity-10:focus-within {
    --tw-ring-opacity: 0.1;
  }

  .xl\:focus-within\:ring-opacity-20:focus-within {
    --tw-ring-opacity: 0.2;
  }

  .xl\:focus-within\:ring-opacity-25:focus-within {
    --tw-ring-opacity: 0.25;
  }

  .xl\:focus-within\:ring-opacity-30:focus-within {
    --tw-ring-opacity: 0.3;
  }

  .xl\:focus-within\:ring-opacity-40:focus-within {
    --tw-ring-opacity: 0.4;
  }

  .xl\:focus-within\:ring-opacity-50:focus-within {
    --tw-ring-opacity: 0.5;
  }

  .xl\:focus-within\:ring-opacity-60:focus-within {
    --tw-ring-opacity: 0.6;
  }

  .xl\:focus-within\:ring-opacity-70:focus-within {
    --tw-ring-opacity: 0.7;
  }

  .xl\:focus-within\:ring-opacity-75:focus-within {
    --tw-ring-opacity: 0.75;
  }

  .xl\:focus-within\:ring-opacity-80:focus-within {
    --tw-ring-opacity: 0.8;
  }

  .xl\:focus-within\:ring-opacity-90:focus-within {
    --tw-ring-opacity: 0.9;
  }

  .xl\:focus-within\:ring-opacity-95:focus-within {
    --tw-ring-opacity: 0.95;
  }

  .xl\:focus-within\:ring-opacity-100:focus-within {
    --tw-ring-opacity: 1;
  }

  .xl\:focus\:ring-opacity-0:focus {
    --tw-ring-opacity: 0;
  }

  .xl\:focus\:ring-opacity-5:focus {
    --tw-ring-opacity: 0.05;
  }

  .xl\:focus\:ring-opacity-10:focus {
    --tw-ring-opacity: 0.1;
  }

  .xl\:focus\:ring-opacity-20:focus {
    --tw-ring-opacity: 0.2;
  }

  .xl\:focus\:ring-opacity-25:focus {
    --tw-ring-opacity: 0.25;
  }

  .xl\:focus\:ring-opacity-30:focus {
    --tw-ring-opacity: 0.3;
  }

  .xl\:focus\:ring-opacity-40:focus {
    --tw-ring-opacity: 0.4;
  }

  .xl\:focus\:ring-opacity-50:focus {
    --tw-ring-opacity: 0.5;
  }

  .xl\:focus\:ring-opacity-60:focus {
    --tw-ring-opacity: 0.6;
  }

  .xl\:focus\:ring-opacity-70:focus {
    --tw-ring-opacity: 0.7;
  }

  .xl\:focus\:ring-opacity-75:focus {
    --tw-ring-opacity: 0.75;
  }

  .xl\:focus\:ring-opacity-80:focus {
    --tw-ring-opacity: 0.8;
  }

  .xl\:focus\:ring-opacity-90:focus {
    --tw-ring-opacity: 0.9;
  }

  .xl\:focus\:ring-opacity-95:focus {
    --tw-ring-opacity: 0.95;
  }

  .xl\:focus\:ring-opacity-100:focus {
    --tw-ring-opacity: 1;
  }

  .xl\:ring-offset-0 {
    --tw-ring-offset-width: 0px;
  }

  .xl\:ring-offset-1 {
    --tw-ring-offset-width: 1px;
  }

  .xl\:ring-offset-2 {
    --tw-ring-offset-width: 2px;
  }

  .xl\:ring-offset-4 {
    --tw-ring-offset-width: 4px;
  }

  .xl\:ring-offset-8 {
    --tw-ring-offset-width: 8px;
  }

  .xl\:focus-within\:ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px;
  }

  .xl\:focus-within\:ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px;
  }

  .xl\:focus-within\:ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px;
  }

  .xl\:focus-within\:ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px;
  }

  .xl\:focus-within\:ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px;
  }

  .xl\:focus\:ring-offset-0:focus {
    --tw-ring-offset-width: 0px;
  }

  .xl\:focus\:ring-offset-1:focus {
    --tw-ring-offset-width: 1px;
  }

  .xl\:focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
  }

  .xl\:focus\:ring-offset-4:focus {
    --tw-ring-offset-width: 4px;
  }

  .xl\:focus\:ring-offset-8:focus {
    --tw-ring-offset-width: 8px;
  }

  .xl\:ring-offset-trans {
    --tw-ring-offset-color: transparent;
  }

  .xl\:ring-offset-white {
    --tw-ring-offset-color: #ffffff;
  }

  .xl\:ring-offset-black {
    --tw-ring-offset-color: #000000;
  }

  .xl\:ring-offset-red {
    --tw-ring-offset-color: #FA5555;
  }

  .xl\:ring-offset-green {
    --tw-ring-offset-color: #2ECC71;
  }

  .xl\:ring-offset-yellow {
    --tw-ring-offset-color: #F4A72C;
  }

  .xl\:ring-offset-brand {
    --tw-ring-offset-color: #2e5bff;
  }

  .xl\:focus-within\:ring-offset-trans:focus-within {
    --tw-ring-offset-color: transparent;
  }

  .xl\:focus-within\:ring-offset-white:focus-within {
    --tw-ring-offset-color: #ffffff;
  }

  .xl\:focus-within\:ring-offset-black:focus-within {
    --tw-ring-offset-color: #000000;
  }

  .xl\:focus-within\:ring-offset-red:focus-within {
    --tw-ring-offset-color: #FA5555;
  }

  .xl\:focus-within\:ring-offset-green:focus-within {
    --tw-ring-offset-color: #2ECC71;
  }

  .xl\:focus-within\:ring-offset-yellow:focus-within {
    --tw-ring-offset-color: #F4A72C;
  }

  .xl\:focus-within\:ring-offset-brand:focus-within {
    --tw-ring-offset-color: #2e5bff;
  }

  .xl\:focus\:ring-offset-trans:focus {
    --tw-ring-offset-color: transparent;
  }

  .xl\:focus\:ring-offset-white:focus {
    --tw-ring-offset-color: #ffffff;
  }

  .xl\:focus\:ring-offset-black:focus {
    --tw-ring-offset-color: #000000;
  }

  .xl\:focus\:ring-offset-red:focus {
    --tw-ring-offset-color: #FA5555;
  }

  .xl\:focus\:ring-offset-green:focus {
    --tw-ring-offset-color: #2ECC71;
  }

  .xl\:focus\:ring-offset-yellow:focus {
    --tw-ring-offset-color: #F4A72C;
  }

  .xl\:focus\:ring-offset-brand:focus {
    --tw-ring-offset-color: #2e5bff;
  }

  .xl\:filter {
    --tw-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
    --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
    -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
            filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .xl\:filter-none {
    -webkit-filter: none;
            filter: none;
  }

  .xl\:blur-0 {
    --tw-blur: blur(0);
  }

  .xl\:blur-none {
    --tw-blur: blur(0);
  }

  .xl\:blur-sm {
    --tw-blur: blur(4px);
  }

  .xl\:blur {
    --tw-blur: blur(8px);
  }

  .xl\:blur-md {
    --tw-blur: blur(12px);
  }

  .xl\:blur-lg {
    --tw-blur: blur(16px);
  }

  .xl\:blur-xl {
    --tw-blur: blur(24px);
  }

  .xl\:blur-2xl {
    --tw-blur: blur(40px);
  }

  .xl\:blur-3xl {
    --tw-blur: blur(64px);
  }

  .xl\:brightness-0 {
    --tw-brightness: brightness(0);
  }

  .xl\:brightness-50 {
    --tw-brightness: brightness(.5);
  }

  .xl\:brightness-75 {
    --tw-brightness: brightness(.75);
  }

  .xl\:brightness-90 {
    --tw-brightness: brightness(.9);
  }

  .xl\:brightness-95 {
    --tw-brightness: brightness(.95);
  }

  .xl\:brightness-100 {
    --tw-brightness: brightness(1);
  }

  .xl\:brightness-105 {
    --tw-brightness: brightness(1.05);
  }

  .xl\:brightness-110 {
    --tw-brightness: brightness(1.1);
  }

  .xl\:brightness-125 {
    --tw-brightness: brightness(1.25);
  }

  .xl\:brightness-150 {
    --tw-brightness: brightness(1.5);
  }

  .xl\:brightness-200 {
    --tw-brightness: brightness(2);
  }

  .xl\:contrast-0 {
    --tw-contrast: contrast(0);
  }

  .xl\:contrast-50 {
    --tw-contrast: contrast(.5);
  }

  .xl\:contrast-75 {
    --tw-contrast: contrast(.75);
  }

  .xl\:contrast-100 {
    --tw-contrast: contrast(1);
  }

  .xl\:contrast-125 {
    --tw-contrast: contrast(1.25);
  }

  .xl\:contrast-150 {
    --tw-contrast: contrast(1.5);
  }

  .xl\:contrast-200 {
    --tw-contrast: contrast(2);
  }

  .xl\:drop-shadow-sm {
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05));
  }

  .xl\:drop-shadow {
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06));
  }

  .xl\:drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
  }

  .xl\:drop-shadow-lg {
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1));
  }

  .xl\:drop-shadow-xl {
    --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08));
  }

  .xl\:drop-shadow-2xl {
    --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15));
  }

  .xl\:drop-shadow-none {
    --tw-drop-shadow: drop-shadow(0 0 #0000);
  }

  .xl\:grayscale-0 {
    --tw-grayscale: grayscale(0);
  }

  .xl\:grayscale {
    --tw-grayscale: grayscale(100%);
  }

  .xl\:hue-rotate-0 {
    --tw-hue-rotate: hue-rotate(0deg);
  }

  .xl\:hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(15deg);
  }

  .xl\:hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(30deg);
  }

  .xl\:hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(60deg);
  }

  .xl\:hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(90deg);
  }

  .xl\:hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(180deg);
  }

  .xl\:-hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(-180deg);
  }

  .xl\:-hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(-90deg);
  }

  .xl\:-hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(-60deg);
  }

  .xl\:-hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(-30deg);
  }

  .xl\:-hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(-15deg);
  }

  .xl\:invert-0 {
    --tw-invert: invert(0);
  }

  .xl\:invert {
    --tw-invert: invert(100%);
  }

  .xl\:saturate-0 {
    --tw-saturate: saturate(0);
  }

  .xl\:saturate-50 {
    --tw-saturate: saturate(.5);
  }

  .xl\:saturate-100 {
    --tw-saturate: saturate(1);
  }

  .xl\:saturate-150 {
    --tw-saturate: saturate(1.5);
  }

  .xl\:saturate-200 {
    --tw-saturate: saturate(2);
  }

  .xl\:sepia-0 {
    --tw-sepia: sepia(0);
  }

  .xl\:sepia {
    --tw-sepia: sepia(100%);
  }

  .xl\:backdrop-filter {
    --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .xl\:backdrop-filter-none {
    -webkit-backdrop-filter: none;
            backdrop-filter: none;
  }

  .xl\:backdrop-blur-0 {
    --tw-backdrop-blur: blur(0);
  }

  .xl\:backdrop-blur-none {
    --tw-backdrop-blur: blur(0);
  }

  .xl\:backdrop-blur-sm {
    --tw-backdrop-blur: blur(4px);
  }

  .xl\:backdrop-blur {
    --tw-backdrop-blur: blur(8px);
  }

  .xl\:backdrop-blur-md {
    --tw-backdrop-blur: blur(12px);
  }

  .xl\:backdrop-blur-lg {
    --tw-backdrop-blur: blur(16px);
  }

  .xl\:backdrop-blur-xl {
    --tw-backdrop-blur: blur(24px);
  }

  .xl\:backdrop-blur-2xl {
    --tw-backdrop-blur: blur(40px);
  }

  .xl\:backdrop-blur-3xl {
    --tw-backdrop-blur: blur(64px);
  }

  .xl\:backdrop-brightness-0 {
    --tw-backdrop-brightness: brightness(0);
  }

  .xl\:backdrop-brightness-50 {
    --tw-backdrop-brightness: brightness(.5);
  }

  .xl\:backdrop-brightness-75 {
    --tw-backdrop-brightness: brightness(.75);
  }

  .xl\:backdrop-brightness-90 {
    --tw-backdrop-brightness: brightness(.9);
  }

  .xl\:backdrop-brightness-95 {
    --tw-backdrop-brightness: brightness(.95);
  }

  .xl\:backdrop-brightness-100 {
    --tw-backdrop-brightness: brightness(1);
  }

  .xl\:backdrop-brightness-105 {
    --tw-backdrop-brightness: brightness(1.05);
  }

  .xl\:backdrop-brightness-110 {
    --tw-backdrop-brightness: brightness(1.1);
  }

  .xl\:backdrop-brightness-125 {
    --tw-backdrop-brightness: brightness(1.25);
  }

  .xl\:backdrop-brightness-150 {
    --tw-backdrop-brightness: brightness(1.5);
  }

  .xl\:backdrop-brightness-200 {
    --tw-backdrop-brightness: brightness(2);
  }

  .xl\:backdrop-contrast-0 {
    --tw-backdrop-contrast: contrast(0);
  }

  .xl\:backdrop-contrast-50 {
    --tw-backdrop-contrast: contrast(.5);
  }

  .xl\:backdrop-contrast-75 {
    --tw-backdrop-contrast: contrast(.75);
  }

  .xl\:backdrop-contrast-100 {
    --tw-backdrop-contrast: contrast(1);
  }

  .xl\:backdrop-contrast-125 {
    --tw-backdrop-contrast: contrast(1.25);
  }

  .xl\:backdrop-contrast-150 {
    --tw-backdrop-contrast: contrast(1.5);
  }

  .xl\:backdrop-contrast-200 {
    --tw-backdrop-contrast: contrast(2);
  }

  .xl\:backdrop-grayscale-0 {
    --tw-backdrop-grayscale: grayscale(0);
  }

  .xl\:backdrop-grayscale {
    --tw-backdrop-grayscale: grayscale(100%);
  }

  .xl\:backdrop-hue-rotate-0 {
    --tw-backdrop-hue-rotate: hue-rotate(0deg);
  }

  .xl\:backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(15deg);
  }

  .xl\:backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(30deg);
  }

  .xl\:backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(60deg);
  }

  .xl\:backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(90deg);
  }

  .xl\:backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(180deg);
  }

  .xl\:-backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(-180deg);
  }

  .xl\:-backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(-90deg);
  }

  .xl\:-backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(-60deg);
  }

  .xl\:-backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(-30deg);
  }

  .xl\:-backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(-15deg);
  }

  .xl\:backdrop-invert-0 {
    --tw-backdrop-invert: invert(0);
  }

  .xl\:backdrop-invert {
    --tw-backdrop-invert: invert(100%);
  }

  .xl\:backdrop-opacity-0 {
    --tw-backdrop-opacity: opacity(0);
  }

  .xl\:backdrop-opacity-5 {
    --tw-backdrop-opacity: opacity(0.05);
  }

  .xl\:backdrop-opacity-10 {
    --tw-backdrop-opacity: opacity(0.1);
  }

  .xl\:backdrop-opacity-20 {
    --tw-backdrop-opacity: opacity(0.2);
  }

  .xl\:backdrop-opacity-25 {
    --tw-backdrop-opacity: opacity(0.25);
  }

  .xl\:backdrop-opacity-30 {
    --tw-backdrop-opacity: opacity(0.3);
  }

  .xl\:backdrop-opacity-40 {
    --tw-backdrop-opacity: opacity(0.4);
  }

  .xl\:backdrop-opacity-50 {
    --tw-backdrop-opacity: opacity(0.5);
  }

  .xl\:backdrop-opacity-60 {
    --tw-backdrop-opacity: opacity(0.6);
  }

  .xl\:backdrop-opacity-70 {
    --tw-backdrop-opacity: opacity(0.7);
  }

  .xl\:backdrop-opacity-75 {
    --tw-backdrop-opacity: opacity(0.75);
  }

  .xl\:backdrop-opacity-80 {
    --tw-backdrop-opacity: opacity(0.8);
  }

  .xl\:backdrop-opacity-90 {
    --tw-backdrop-opacity: opacity(0.9);
  }

  .xl\:backdrop-opacity-95 {
    --tw-backdrop-opacity: opacity(0.95);
  }

  .xl\:backdrop-opacity-100 {
    --tw-backdrop-opacity: opacity(1);
  }

  .xl\:backdrop-saturate-0 {
    --tw-backdrop-saturate: saturate(0);
  }

  .xl\:backdrop-saturate-50 {
    --tw-backdrop-saturate: saturate(.5);
  }

  .xl\:backdrop-saturate-100 {
    --tw-backdrop-saturate: saturate(1);
  }

  .xl\:backdrop-saturate-150 {
    --tw-backdrop-saturate: saturate(1.5);
  }

  .xl\:backdrop-saturate-200 {
    --tw-backdrop-saturate: saturate(2);
  }

  .xl\:backdrop-sepia-0 {
    --tw-backdrop-sepia: sepia(0);
  }

  .xl\:backdrop-sepia {
    --tw-backdrop-sepia: sepia(100%);
  }
}

@media (min-width: 1536px) {
  .\32xl\:isolate {
    isolation: isolate;
  }

  .\32xl\:isolation-auto {
    isolation: auto;
  }

  .\32xl\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .\32xl\:overflow-ellipsis {
    text-overflow: ellipsis;
  }

  .\32xl\:overflow-clip {
    text-overflow: clip;
  }

  .\32xl\:decoration-slice {
    -webkit-box-decoration-break: slice;
            box-decoration-break: slice;
  }

  .\32xl\:decoration-clone {
    -webkit-box-decoration-break: clone;
            box-decoration-break: clone;
  }

  .\32xl\:bg-origin-border {
    background-origin: border-box;
  }

  .\32xl\:bg-origin-padding {
    background-origin: padding-box;
  }

  .\32xl\:bg-origin-content {
    background-origin: content-box;
  }

  .\32xl\:bg-blend-normal {
    background-blend-mode: normal;
  }

  .\32xl\:bg-blend-multiply {
    background-blend-mode: multiply;
  }

  .\32xl\:bg-blend-screen {
    background-blend-mode: screen;
  }

  .\32xl\:bg-blend-overlay {
    background-blend-mode: overlay;
  }

  .\32xl\:bg-blend-darken {
    background-blend-mode: darken;
  }

  .\32xl\:bg-blend-lighten {
    background-blend-mode: lighten;
  }

  .\32xl\:bg-blend-color-dodge {
    background-blend-mode: color-dodge;
  }

  .\32xl\:bg-blend-color-burn {
    background-blend-mode: color-burn;
  }

  .\32xl\:bg-blend-hard-light {
    background-blend-mode: hard-light;
  }

  .\32xl\:bg-blend-soft-light {
    background-blend-mode: soft-light;
  }

  .\32xl\:bg-blend-difference {
    background-blend-mode: difference;
  }

  .\32xl\:bg-blend-exclusion {
    background-blend-mode: exclusion;
  }

  .\32xl\:bg-blend-hue {
    background-blend-mode: hue;
  }

  .\32xl\:bg-blend-saturation {
    background-blend-mode: saturation;
  }

  .\32xl\:bg-blend-color {
    background-blend-mode: color;
  }

  .\32xl\:bg-blend-luminosity {
    background-blend-mode: luminosity;
  }

  .\32xl\:mix-blend-normal {
    mix-blend-mode: normal;
  }

  .\32xl\:mix-blend-multiply {
    mix-blend-mode: multiply;
  }

  .\32xl\:mix-blend-screen {
    mix-blend-mode: screen;
  }

  .\32xl\:mix-blend-overlay {
    mix-blend-mode: overlay;
  }

  .\32xl\:mix-blend-darken {
    mix-blend-mode: darken;
  }

  .\32xl\:mix-blend-lighten {
    mix-blend-mode: lighten;
  }

  .\32xl\:mix-blend-color-dodge {
    mix-blend-mode: color-dodge;
  }

  .\32xl\:mix-blend-color-burn {
    mix-blend-mode: color-burn;
  }

  .\32xl\:mix-blend-hard-light {
    mix-blend-mode: hard-light;
  }

  .\32xl\:mix-blend-soft-light {
    mix-blend-mode: soft-light;
  }

  .\32xl\:mix-blend-difference {
    mix-blend-mode: difference;
  }

  .\32xl\:mix-blend-exclusion {
    mix-blend-mode: exclusion;
  }

  .\32xl\:mix-blend-hue {
    mix-blend-mode: hue;
  }

  .\32xl\:mix-blend-saturation {
    mix-blend-mode: saturation;
  }

  .\32xl\:mix-blend-color {
    mix-blend-mode: color;
  }

  .\32xl\:mix-blend-luminosity {
    mix-blend-mode: luminosity;
  }

  .\32xl\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:focus-within\:ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:focus-within\:ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:focus-within\:ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:focus-within\:ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:focus-within\:ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:focus-within\:ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:focus\:ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:focus\:ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .\32xl\:ring-inset {
    --tw-ring-inset: inset;
  }

  .\32xl\:focus-within\:ring-inset:focus-within {
    --tw-ring-inset: inset;
  }

  .\32xl\:focus\:ring-inset:focus {
    --tw-ring-inset: inset;
  }

  .\32xl\:ring-trans {
    --tw-ring-color: transparent;
  }

  .\32xl\:ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .\32xl\:ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .\32xl\:ring-red {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 85, 85, var(--tw-ring-opacity));
  }

  .\32xl\:ring-green {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 204, 113, var(--tw-ring-opacity));
  }

  .\32xl\:ring-yellow {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 167, 44, var(--tw-ring-opacity));
  }

  .\32xl\:ring-brand {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 91, 255, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:ring-trans:focus-within {
    --tw-ring-color: transparent;
  }

  .\32xl\:focus-within\:ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:ring-red:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 85, 85, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:ring-green:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 204, 113, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:ring-yellow:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 167, 44, var(--tw-ring-opacity));
  }

  .\32xl\:focus-within\:ring-brand:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 91, 255, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:ring-trans:focus {
    --tw-ring-color: transparent;
  }

  .\32xl\:focus\:ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:ring-red:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(250, 85, 85, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:ring-green:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 204, 113, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:ring-yellow:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 167, 44, var(--tw-ring-opacity));
  }

  .\32xl\:focus\:ring-brand:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(46, 91, 255, var(--tw-ring-opacity));
  }

  .\32xl\:ring-opacity-0 {
    --tw-ring-opacity: 0;
  }

  .\32xl\:ring-opacity-5 {
    --tw-ring-opacity: 0.05;
  }

  .\32xl\:ring-opacity-10 {
    --tw-ring-opacity: 0.1;
  }

  .\32xl\:ring-opacity-20 {
    --tw-ring-opacity: 0.2;
  }

  .\32xl\:ring-opacity-25 {
    --tw-ring-opacity: 0.25;
  }

  .\32xl\:ring-opacity-30 {
    --tw-ring-opacity: 0.3;
  }

  .\32xl\:ring-opacity-40 {
    --tw-ring-opacity: 0.4;
  }

  .\32xl\:ring-opacity-50 {
    --tw-ring-opacity: 0.5;
  }

  .\32xl\:ring-opacity-60 {
    --tw-ring-opacity: 0.6;
  }

  .\32xl\:ring-opacity-70 {
    --tw-ring-opacity: 0.7;
  }

  .\32xl\:ring-opacity-75 {
    --tw-ring-opacity: 0.75;
  }

  .\32xl\:ring-opacity-80 {
    --tw-ring-opacity: 0.8;
  }

  .\32xl\:ring-opacity-90 {
    --tw-ring-opacity: 0.9;
  }

  .\32xl\:ring-opacity-95 {
    --tw-ring-opacity: 0.95;
  }

  .\32xl\:ring-opacity-100 {
    --tw-ring-opacity: 1;
  }

  .\32xl\:focus-within\:ring-opacity-0:focus-within {
    --tw-ring-opacity: 0;
  }

  .\32xl\:focus-within\:ring-opacity-5:focus-within {
    --tw-ring-opacity: 0.05;
  }

  .\32xl\:focus-within\:ring-opacity-10:focus-within {
    --tw-ring-opacity: 0.1;
  }

  .\32xl\:focus-within\:ring-opacity-20:focus-within {
    --tw-ring-opacity: 0.2;
  }

  .\32xl\:focus-within\:ring-opacity-25:focus-within {
    --tw-ring-opacity: 0.25;
  }

  .\32xl\:focus-within\:ring-opacity-30:focus-within {
    --tw-ring-opacity: 0.3;
  }

  .\32xl\:focus-within\:ring-opacity-40:focus-within {
    --tw-ring-opacity: 0.4;
  }

  .\32xl\:focus-within\:ring-opacity-50:focus-within {
    --tw-ring-opacity: 0.5;
  }

  .\32xl\:focus-within\:ring-opacity-60:focus-within {
    --tw-ring-opacity: 0.6;
  }

  .\32xl\:focus-within\:ring-opacity-70:focus-within {
    --tw-ring-opacity: 0.7;
  }

  .\32xl\:focus-within\:ring-opacity-75:focus-within {
    --tw-ring-opacity: 0.75;
  }

  .\32xl\:focus-within\:ring-opacity-80:focus-within {
    --tw-ring-opacity: 0.8;
  }

  .\32xl\:focus-within\:ring-opacity-90:focus-within {
    --tw-ring-opacity: 0.9;
  }

  .\32xl\:focus-within\:ring-opacity-95:focus-within {
    --tw-ring-opacity: 0.95;
  }

  .\32xl\:focus-within\:ring-opacity-100:focus-within {
    --tw-ring-opacity: 1;
  }

  .\32xl\:focus\:ring-opacity-0:focus {
    --tw-ring-opacity: 0;
  }

  .\32xl\:focus\:ring-opacity-5:focus {
    --tw-ring-opacity: 0.05;
  }

  .\32xl\:focus\:ring-opacity-10:focus {
    --tw-ring-opacity: 0.1;
  }

  .\32xl\:focus\:ring-opacity-20:focus {
    --tw-ring-opacity: 0.2;
  }

  .\32xl\:focus\:ring-opacity-25:focus {
    --tw-ring-opacity: 0.25;
  }

  .\32xl\:focus\:ring-opacity-30:focus {
    --tw-ring-opacity: 0.3;
  }

  .\32xl\:focus\:ring-opacity-40:focus {
    --tw-ring-opacity: 0.4;
  }

  .\32xl\:focus\:ring-opacity-50:focus {
    --tw-ring-opacity: 0.5;
  }

  .\32xl\:focus\:ring-opacity-60:focus {
    --tw-ring-opacity: 0.6;
  }

  .\32xl\:focus\:ring-opacity-70:focus {
    --tw-ring-opacity: 0.7;
  }

  .\32xl\:focus\:ring-opacity-75:focus {
    --tw-ring-opacity: 0.75;
  }

  .\32xl\:focus\:ring-opacity-80:focus {
    --tw-ring-opacity: 0.8;
  }

  .\32xl\:focus\:ring-opacity-90:focus {
    --tw-ring-opacity: 0.9;
  }

  .\32xl\:focus\:ring-opacity-95:focus {
    --tw-ring-opacity: 0.95;
  }

  .\32xl\:focus\:ring-opacity-100:focus {
    --tw-ring-opacity: 1;
  }

  .\32xl\:ring-offset-0 {
    --tw-ring-offset-width: 0px;
  }

  .\32xl\:ring-offset-1 {
    --tw-ring-offset-width: 1px;
  }

  .\32xl\:ring-offset-2 {
    --tw-ring-offset-width: 2px;
  }

  .\32xl\:ring-offset-4 {
    --tw-ring-offset-width: 4px;
  }

  .\32xl\:ring-offset-8 {
    --tw-ring-offset-width: 8px;
  }

  .\32xl\:focus-within\:ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px;
  }

  .\32xl\:focus-within\:ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px;
  }

  .\32xl\:focus-within\:ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px;
  }

  .\32xl\:focus-within\:ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px;
  }

  .\32xl\:focus-within\:ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px;
  }

  .\32xl\:focus\:ring-offset-0:focus {
    --tw-ring-offset-width: 0px;
  }

  .\32xl\:focus\:ring-offset-1:focus {
    --tw-ring-offset-width: 1px;
  }

  .\32xl\:focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
  }

  .\32xl\:focus\:ring-offset-4:focus {
    --tw-ring-offset-width: 4px;
  }

  .\32xl\:focus\:ring-offset-8:focus {
    --tw-ring-offset-width: 8px;
  }

  .\32xl\:ring-offset-trans {
    --tw-ring-offset-color: transparent;
  }

  .\32xl\:ring-offset-white {
    --tw-ring-offset-color: #ffffff;
  }

  .\32xl\:ring-offset-black {
    --tw-ring-offset-color: #000000;
  }

  .\32xl\:ring-offset-red {
    --tw-ring-offset-color: #FA5555;
  }

  .\32xl\:ring-offset-green {
    --tw-ring-offset-color: #2ECC71;
  }

  .\32xl\:ring-offset-yellow {
    --tw-ring-offset-color: #F4A72C;
  }

  .\32xl\:ring-offset-brand {
    --tw-ring-offset-color: #2e5bff;
  }

  .\32xl\:focus-within\:ring-offset-trans:focus-within {
    --tw-ring-offset-color: transparent;
  }

  .\32xl\:focus-within\:ring-offset-white:focus-within {
    --tw-ring-offset-color: #ffffff;
  }

  .\32xl\:focus-within\:ring-offset-black:focus-within {
    --tw-ring-offset-color: #000000;
  }

  .\32xl\:focus-within\:ring-offset-red:focus-within {
    --tw-ring-offset-color: #FA5555;
  }

  .\32xl\:focus-within\:ring-offset-green:focus-within {
    --tw-ring-offset-color: #2ECC71;
  }

  .\32xl\:focus-within\:ring-offset-yellow:focus-within {
    --tw-ring-offset-color: #F4A72C;
  }

  .\32xl\:focus-within\:ring-offset-brand:focus-within {
    --tw-ring-offset-color: #2e5bff;
  }

  .\32xl\:focus\:ring-offset-trans:focus {
    --tw-ring-offset-color: transparent;
  }

  .\32xl\:focus\:ring-offset-white:focus {
    --tw-ring-offset-color: #ffffff;
  }

  .\32xl\:focus\:ring-offset-black:focus {
    --tw-ring-offset-color: #000000;
  }

  .\32xl\:focus\:ring-offset-red:focus {
    --tw-ring-offset-color: #FA5555;
  }

  .\32xl\:focus\:ring-offset-green:focus {
    --tw-ring-offset-color: #2ECC71;
  }

  .\32xl\:focus\:ring-offset-yellow:focus {
    --tw-ring-offset-color: #F4A72C;
  }

  .\32xl\:focus\:ring-offset-brand:focus {
    --tw-ring-offset-color: #2e5bff;
  }

  .\32xl\:filter {
    --tw-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
    --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
    -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
            filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .\32xl\:filter-none {
    -webkit-filter: none;
            filter: none;
  }

  .\32xl\:blur-0 {
    --tw-blur: blur(0);
  }

  .\32xl\:blur-none {
    --tw-blur: blur(0);
  }

  .\32xl\:blur-sm {
    --tw-blur: blur(4px);
  }

  .\32xl\:blur {
    --tw-blur: blur(8px);
  }

  .\32xl\:blur-md {
    --tw-blur: blur(12px);
  }

  .\32xl\:blur-lg {
    --tw-blur: blur(16px);
  }

  .\32xl\:blur-xl {
    --tw-blur: blur(24px);
  }

  .\32xl\:blur-2xl {
    --tw-blur: blur(40px);
  }

  .\32xl\:blur-3xl {
    --tw-blur: blur(64px);
  }

  .\32xl\:brightness-0 {
    --tw-brightness: brightness(0);
  }

  .\32xl\:brightness-50 {
    --tw-brightness: brightness(.5);
  }

  .\32xl\:brightness-75 {
    --tw-brightness: brightness(.75);
  }

  .\32xl\:brightness-90 {
    --tw-brightness: brightness(.9);
  }

  .\32xl\:brightness-95 {
    --tw-brightness: brightness(.95);
  }

  .\32xl\:brightness-100 {
    --tw-brightness: brightness(1);
  }

  .\32xl\:brightness-105 {
    --tw-brightness: brightness(1.05);
  }

  .\32xl\:brightness-110 {
    --tw-brightness: brightness(1.1);
  }

  .\32xl\:brightness-125 {
    --tw-brightness: brightness(1.25);
  }

  .\32xl\:brightness-150 {
    --tw-brightness: brightness(1.5);
  }

  .\32xl\:brightness-200 {
    --tw-brightness: brightness(2);
  }

  .\32xl\:contrast-0 {
    --tw-contrast: contrast(0);
  }

  .\32xl\:contrast-50 {
    --tw-contrast: contrast(.5);
  }

  .\32xl\:contrast-75 {
    --tw-contrast: contrast(.75);
  }

  .\32xl\:contrast-100 {
    --tw-contrast: contrast(1);
  }

  .\32xl\:contrast-125 {
    --tw-contrast: contrast(1.25);
  }

  .\32xl\:contrast-150 {
    --tw-contrast: contrast(1.5);
  }

  .\32xl\:contrast-200 {
    --tw-contrast: contrast(2);
  }

  .\32xl\:drop-shadow-sm {
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05));
  }

  .\32xl\:drop-shadow {
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06));
  }

  .\32xl\:drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
  }

  .\32xl\:drop-shadow-lg {
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1));
  }

  .\32xl\:drop-shadow-xl {
    --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08));
  }

  .\32xl\:drop-shadow-2xl {
    --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15));
  }

  .\32xl\:drop-shadow-none {
    --tw-drop-shadow: drop-shadow(0 0 #0000);
  }

  .\32xl\:grayscale-0 {
    --tw-grayscale: grayscale(0);
  }

  .\32xl\:grayscale {
    --tw-grayscale: grayscale(100%);
  }

  .\32xl\:hue-rotate-0 {
    --tw-hue-rotate: hue-rotate(0deg);
  }

  .\32xl\:hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(15deg);
  }

  .\32xl\:hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(30deg);
  }

  .\32xl\:hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(60deg);
  }

  .\32xl\:hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(90deg);
  }

  .\32xl\:hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(180deg);
  }

  .\32xl\:-hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(-180deg);
  }

  .\32xl\:-hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(-90deg);
  }

  .\32xl\:-hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(-60deg);
  }

  .\32xl\:-hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(-30deg);
  }

  .\32xl\:-hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(-15deg);
  }

  .\32xl\:invert-0 {
    --tw-invert: invert(0);
  }

  .\32xl\:invert {
    --tw-invert: invert(100%);
  }

  .\32xl\:saturate-0 {
    --tw-saturate: saturate(0);
  }

  .\32xl\:saturate-50 {
    --tw-saturate: saturate(.5);
  }

  .\32xl\:saturate-100 {
    --tw-saturate: saturate(1);
  }

  .\32xl\:saturate-150 {
    --tw-saturate: saturate(1.5);
  }

  .\32xl\:saturate-200 {
    --tw-saturate: saturate(2);
  }

  .\32xl\:sepia-0 {
    --tw-sepia: sepia(0);
  }

  .\32xl\:sepia {
    --tw-sepia: sepia(100%);
  }

  .\32xl\:backdrop-filter {
    --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/);
    --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  .\32xl\:backdrop-filter-none {
    -webkit-backdrop-filter: none;
            backdrop-filter: none;
  }

  .\32xl\:backdrop-blur-0 {
    --tw-backdrop-blur: blur(0);
  }

  .\32xl\:backdrop-blur-none {
    --tw-backdrop-blur: blur(0);
  }

  .\32xl\:backdrop-blur-sm {
    --tw-backdrop-blur: blur(4px);
  }

  .\32xl\:backdrop-blur {
    --tw-backdrop-blur: blur(8px);
  }

  .\32xl\:backdrop-blur-md {
    --tw-backdrop-blur: blur(12px);
  }

  .\32xl\:backdrop-blur-lg {
    --tw-backdrop-blur: blur(16px);
  }

  .\32xl\:backdrop-blur-xl {
    --tw-backdrop-blur: blur(24px);
  }

  .\32xl\:backdrop-blur-2xl {
    --tw-backdrop-blur: blur(40px);
  }

  .\32xl\:backdrop-blur-3xl {
    --tw-backdrop-blur: blur(64px);
  }

  .\32xl\:backdrop-brightness-0 {
    --tw-backdrop-brightness: brightness(0);
  }

  .\32xl\:backdrop-brightness-50 {
    --tw-backdrop-brightness: brightness(.5);
  }

  .\32xl\:backdrop-brightness-75 {
    --tw-backdrop-brightness: brightness(.75);
  }

  .\32xl\:backdrop-brightness-90 {
    --tw-backdrop-brightness: brightness(.9);
  }

  .\32xl\:backdrop-brightness-95 {
    --tw-backdrop-brightness: brightness(.95);
  }

  .\32xl\:backdrop-brightness-100 {
    --tw-backdrop-brightness: brightness(1);
  }

  .\32xl\:backdrop-brightness-105 {
    --tw-backdrop-brightness: brightness(1.05);
  }

  .\32xl\:backdrop-brightness-110 {
    --tw-backdrop-brightness: brightness(1.1);
  }

  .\32xl\:backdrop-brightness-125 {
    --tw-backdrop-brightness: brightness(1.25);
  }

  .\32xl\:backdrop-brightness-150 {
    --tw-backdrop-brightness: brightness(1.5);
  }

  .\32xl\:backdrop-brightness-200 {
    --tw-backdrop-brightness: brightness(2);
  }

  .\32xl\:backdrop-contrast-0 {
    --tw-backdrop-contrast: contrast(0);
  }

  .\32xl\:backdrop-contrast-50 {
    --tw-backdrop-contrast: contrast(.5);
  }

  .\32xl\:backdrop-contrast-75 {
    --tw-backdrop-contrast: contrast(.75);
  }

  .\32xl\:backdrop-contrast-100 {
    --tw-backdrop-contrast: contrast(1);
  }

  .\32xl\:backdrop-contrast-125 {
    --tw-backdrop-contrast: contrast(1.25);
  }

  .\32xl\:backdrop-contrast-150 {
    --tw-backdrop-contrast: contrast(1.5);
  }

  .\32xl\:backdrop-contrast-200 {
    --tw-backdrop-contrast: contrast(2);
  }

  .\32xl\:backdrop-grayscale-0 {
    --tw-backdrop-grayscale: grayscale(0);
  }

  .\32xl\:backdrop-grayscale {
    --tw-backdrop-grayscale: grayscale(100%);
  }

  .\32xl\:backdrop-hue-rotate-0 {
    --tw-backdrop-hue-rotate: hue-rotate(0deg);
  }

  .\32xl\:backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(15deg);
  }

  .\32xl\:backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(30deg);
  }

  .\32xl\:backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(60deg);
  }

  .\32xl\:backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(90deg);
  }

  .\32xl\:backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(180deg);
  }

  .\32xl\:-backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(-180deg);
  }

  .\32xl\:-backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(-90deg);
  }

  .\32xl\:-backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(-60deg);
  }

  .\32xl\:-backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(-30deg);
  }

  .\32xl\:-backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(-15deg);
  }

  .\32xl\:backdrop-invert-0 {
    --tw-backdrop-invert: invert(0);
  }

  .\32xl\:backdrop-invert {
    --tw-backdrop-invert: invert(100%);
  }

  .\32xl\:backdrop-opacity-0 {
    --tw-backdrop-opacity: opacity(0);
  }

  .\32xl\:backdrop-opacity-5 {
    --tw-backdrop-opacity: opacity(0.05);
  }

  .\32xl\:backdrop-opacity-10 {
    --tw-backdrop-opacity: opacity(0.1);
  }

  .\32xl\:backdrop-opacity-20 {
    --tw-backdrop-opacity: opacity(0.2);
  }

  .\32xl\:backdrop-opacity-25 {
    --tw-backdrop-opacity: opacity(0.25);
  }

  .\32xl\:backdrop-opacity-30 {
    --tw-backdrop-opacity: opacity(0.3);
  }

  .\32xl\:backdrop-opacity-40 {
    --tw-backdrop-opacity: opacity(0.4);
  }

  .\32xl\:backdrop-opacity-50 {
    --tw-backdrop-opacity: opacity(0.5);
  }

  .\32xl\:backdrop-opacity-60 {
    --tw-backdrop-opacity: opacity(0.6);
  }

  .\32xl\:backdrop-opacity-70 {
    --tw-backdrop-opacity: opacity(0.7);
  }

  .\32xl\:backdrop-opacity-75 {
    --tw-backdrop-opacity: opacity(0.75);
  }

  .\32xl\:backdrop-opacity-80 {
    --tw-backdrop-opacity: opacity(0.8);
  }

  .\32xl\:backdrop-opacity-90 {
    --tw-backdrop-opacity: opacity(0.9);
  }

  .\32xl\:backdrop-opacity-95 {
    --tw-backdrop-opacity: opacity(0.95);
  }

  .\32xl\:backdrop-opacity-100 {
    --tw-backdrop-opacity: opacity(1);
  }

  .\32xl\:backdrop-saturate-0 {
    --tw-backdrop-saturate: saturate(0);
  }

  .\32xl\:backdrop-saturate-50 {
    --tw-backdrop-saturate: saturate(.5);
  }

  .\32xl\:backdrop-saturate-100 {
    --tw-backdrop-saturate: saturate(1);
  }

  .\32xl\:backdrop-saturate-150 {
    --tw-backdrop-saturate: saturate(1.5);
  }

  .\32xl\:backdrop-saturate-200 {
    --tw-backdrop-saturate: saturate(2);
  }

  .\32xl\:backdrop-sepia-0 {
    --tw-backdrop-sepia: sepia(0);
  }

  .\32xl\:backdrop-sepia {
    --tw-backdrop-sepia: sepia(100%);
  }
}

:root {
  --primary-color: #2e5bff;
}

.wrapper--3XC-b {
  padding: 24px;
  text-align: center;
  margin-bottom: 50px;
  width: 100%;
}

.wrapper--3XC-b .title--oEsWx {
  font-size: 20px;
  margin-bottom: 24px;
  overflow-wrap: anywhere;
  font-weight: bold;
}

.wrapper--3XC-b .info--1dT3y {
  font-size: 16px;
  color: #666;
  margin-bottom: 17px;
}

