@import url('../../style/common.less');
.wrapper {
  padding: 24px;
  text-align: center;
  margin-bottom: 50px;
  width: 100%;
  .title {
    font-size: 20px;
    margin-bottom: 24px;
    overflow-wrap: anywhere;
    font-weight: bold;
  }
  .info {
    font-size: 16px;
    color: #666;
    margin-bottom: 17px;
  }
}

@primary-color: #2e5bff;